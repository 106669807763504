<div class="uk-grid">
  <div class="uk-width-1-1 navbar">
    <p class="nav-text">
      <span *ngIf="id===null">Definicja dodatkowego parametru</span>
      <span *ngIf="id!==null">Modyfikacja dodatkowego parametru</span>
      <button class="uk-button uk-button-primary padding-horizontal-small button-opt " [attr.uk-tooltip]="'Wróć'" [routerLink]="'/client/'+idClient+'/card/parameters'"><span uk-icon="sign-out"></span></button>
      <button *ngIf="id !== null" class="uk-button uk-button-danger padding-horizontal-small uk-margin-remove-right button-opt " (click)="delete()" [attr.uk-tooltip]="'Usuń dane dostępowe'"><span uk-icon="ban"></span></button>
    </p>
  </div>

  <div class="uk-width-1-1 inner-shadow">
    <div class="uk-card uk-card-default uk-card-body uk-margin-medium-right uk-margin-medium-top">
      <div class="mobile-overflow">
          <div class="uk-grid">
            <div class="uk-width-1-3">
              <label>Typ</label>
              <select class="uk-select" [(ngModel)]="model.type_id" *ngIf="id === null" (change)="getType(model.type_id)">
                <option *ngFor="let type of types" value="{{type.id}}" >{{type.name}}</option>
              </select>
              <input class="uk-input" [(ngModel)]="model.name" name="name" disabled *ngIf="id !== null">
            </div>
            <div class="uk-width-2-3" *ngIf="type.code === 'INVOICING_VAT_RATE'">
              <label>{{'Stawka'|translate}}</label>
              <select class="uk-select" [(ngModel)]="model.value">
                <option value="23">23 %</option>
                <option value="np">{{'Nie podlega'|translate}}</option>
              </select>
            </div>
            <div class="uk-width-2-3" *ngIf="type.code === 'SHIPMENT_COUNT_VOLUME'">
              <label>{{'Wolumen przesyłek'|translate}}</label>
              <select class="uk-select" [(ngModel)]="model.value">
                <option value="A">{{'shipment_count_volume_A'|translate}}</option>
                <option value="B">{{'shipment_count_volume_B'|translate}}</option>
                <option value="C">{{'shipment_count_volume_C'|translate}}</option>
              </select>
            </div>
            <div class="uk-width-2-3" *ngIf="type.code === 'ACCOUNT_PERIOD'">
              <label>{{'account_peroid'|translate}}</label>
              <select class="uk-select" [(ngModel)]="model.value">
                <option value="monthly">{{'monthly'|translate}}</option>
                <option value="half_monthly">{{'half_monthly'|translate}}</option>
                <option value="weekly">{{'weekly'|translate}}</option>
              </select>
            </div>
            <div class="uk-width-2-3" *ngIf="type.code === 'SIDE_FORMULA_LIMIT' && model.type_id !== null">
              <label>Limit reguły wymiarów przesyłki</label>
              <input class="uk-input" type="number" min="0" step="0" value="300" [(ngModel)]="model.value">
            </div>

            <div class="uk-width-2-3" *ngIf="!['SIDE_FORMULA_LIMIT', 'ACCOUNT_PERIOD', 'SHIPMENT_COUNT_VOLUME'].includes(type.code) && model.type_id !== null ">
              <div *ngIf="id !== null">
                <label>Wartość</label>
                <input class="uk-input" [(ngModel)]="model.value" name="value" disabled>
              </div>
              <div *ngIf="id === null && type.code === 'ROMANIA_SAMEDAY_CLIENT_CONTACT_NAME'">
                  <label>{{'smd_contact' | translate}}</label>
                  <input autocomplete="off" [class.uk-form-danger]="contactInput.touched && contact_id.value == ''" placeholder="Brak" (keyup)="searchContact('romania_sameday', contactInput)" type="text" #contactInput="ngModel" name="value" [(ngModel)]="model.value" class="uk-input" #contactField>

                  <div style="position: absolute; max-height: 200px;" class="uk-overflow-auto">
                    <ul class="uk-list uk-list-divider uk-position-relative uk-margin-remove-top" style="background-color: white; width: 100%;" *ngIf="searchOptions.length > 0" [style.width]="contactField.clientWidth + 'px'">
                      <li *ngFor="let contact of searchOptions" (click)="setContact(contact);" style="padding: 5px">{{contact.name}}</li>
                    </ul>
                  </div>
                  <input type="hidden" #contact_id="ngModel" autocomplete="none" name="hidden_value" [(ngModel)]="model.hidden_value"  class="uk-input">
              </div>
              <div *ngIf="id === null && type.code === 'HUNGARY_SAMEDAY_CLIENT_CONTACT_NAME'">
                <label>{{'smd_contact' | translate}}</label>
                <input autocomplete="off" [class.uk-form-danger]="contactInput.touched && contact_id.value == ''" placeholder="Brak" (keyup)="searchContact('hungary_sameday', contactInput)" type="text" #contactInput="ngModel" name="value" [(ngModel)]="model.value" class="uk-input" #contactField>

                <div style="position: absolute; max-height: 200px;" class="uk-overflow-auto">
                  <ul class="uk-list uk-list-divider uk-position-relative uk-margin-remove-top" style="background-color: white; width: 100%;" *ngIf="searchOptions.length > 0" [style.width]="contactField.clientWidth + 'px'">
                    <li *ngFor="let contact of searchOptions" (click)="setContact(contact);" style="padding: 5px">{{contact.name}}</li>
                  </ul>
                </div>
                <input type="hidden" #contact_id="ngModel" autocomplete="none" name="hidden_value" [(ngModel)]="model.hidden_value"  class="uk-input">
              </div>
              <div *ngIf="id === null && type.code === 'BULGARIA_SAMEDAY_CLIENT_CONTACT_NAME'">
                <label>{{'smd_contact' | translate}}</label>
                <input autocomplete="off" [class.uk-form-danger]="contactInput.touched && contact_id.value == ''" placeholder="Brak" (keyup)="searchContact('bulgaria_sameday', contactInput)" type="text" #contactInput="ngModel" name="value" [(ngModel)]="model.value" class="uk-input" #contactField>

                <div style="position: absolute; max-height: 200px;" class="uk-overflow-auto">
                  <ul class="uk-list uk-list-divider uk-position-relative uk-margin-remove-top" style="background-color: white; width: 100%;" *ngIf="searchOptions.length > 0" [style.width]="contactField.clientWidth + 'px'">
                    <li *ngFor="let contact of searchOptions" (click)="setContact(contact);" style="padding: 5px">{{contact.name}}</li>
                  </ul>
                </div>
                <input type="hidden" #contact_id="ngModel" autocomplete="none" name="hidden_value" [(ngModel)]="model.hidden_value"  class="uk-input">
              </div>
              <div class="uk-width-2-3" *ngIf="type.code === 'EMAG_NAME'">
                <label>Nazwa eMAG</label>
                <input class="uk-input" type="text" value="300" [(ngModel)]="model.value">
              </div>
              <div class="uk-width-2-3" *ngIf="type.code === 'INVOICING_EMAIL_ADDRESS'">
                <label>Adres email</label>
                <input class="uk-input" type="text" value="300" [(ngModel)]="model.value">
              </div>
              <div class="uk-width-2-3" *ngIf="type.code === 'FAKTUROWNIA_ID'">
                <label>Wartość</label>
                <input class="uk-input" type="text" value="300" [(ngModel)]="model.value">
              </div>
            </div>
          </div>

          <div class="uk-grid">
            <div *ngFor="let x of model.extra_parameters; let i = index" class="uk-width-1-3">
                <label>{{model.extra_parameters[i].name | translate}}</label>
                <select class="uk-select" *ngIf="model.extra_parameters[i].type === 'select'" [(ngModel)]="model.extra_parameters[i].value" [disabled]="id !==null">
                  <option *ngFor="let key of model.extra_parameters[i].option_keys" value="{{key}}">
                    {{model.extra_parameters[i].options[key]}}
                  </option>
                </select>
            </div>
          </div>
          <div class="uk-margin" *ngIf="null === id">
            <button class="uk-button uk-button-primary" (click)="submit()">{{'save' | translate}}</button>
          </div>
      </div>
    </div>
  </div>
</div>

<!--
<div *ngFor="let x of model.extra_parameters; let i = index" class="uk-width-1-3">
              <label>{{model.extra_parameters[i].name | translate}}</label>
              <select *ngIf="model.extra_parameters[i].type === 'select'" [(ngModel)]="model.extra_parameters[i].value" class="uk-select">
              </select>
            </div>
    <option *ngFor="let j of Object.keys(model.extra_parameters[i].options)" value="{{j}}">{{model.extra_parameters[i].options[j]}} {{j}}</option>
             -->
