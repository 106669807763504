import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ElementRef, ViewChild} from '@angular/core';
import {ToastsService} from '../../../services/toasts.service';
import {ExportService} from '../../../services/export/export.service';
import {environment} from '../../../../environments/environment';
import {ParcelsService} from '../../../services/parcels/parcels.service';
import {UserModel} from '../../../models/user.model';
import {AuthService} from '../../../services/auth/auth.service';
import {UserRole} from '../../../enums/user-role';
import {DataTableDirective} from 'angular-datatables';
import {identity, Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';

declare var UIkit: any;

@Component({
  selector: 'export-list',
  templateUrl: './export-list.component.html',
  styleUrls: ['./export-list.component.scss']
})
export class ExportListComponent implements OnInit, OnDestroy {
  @Input('type') type = null;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  @ViewChild(DataTableDirective, {static: true}) dtElement: DataTableDirective;
  public dtInit = false;
  dtTranslation = null;

  timeout = 100;
  invoker = '';
  exports: any = [];
  apiUrl = '';
  public userModel: UserModel = new UserModel();
  isOpen: any = null;
  lastEvent = null;

  constructor(
    private authService: AuthService,
    private toastService: ToastsService,
    public exportService: ExportService,
    private parcelService: ParcelsService,
    private http: HttpClient,
  ) {
    this.apiUrl = environment.api;
    this.dtTranslation = toastService.dtTranslation;
  }

  ngOnInit(): void {
    this.authService.isLogged.subscribe((userModel) => {
      this.userModel = userModel;

      if (UserRole.warehouse_worker === this.userModel.role) {
        return;
      }

      this.showExportedFiles();
    });
  }

  lastEventProcessing = false;

  showExportedFiles() {
    if (this.lastEvent != null) {
      clearTimeout(this.lastEvent);
    }

    if (this.lastEventProcessing === true) {
      setTimeout( () => { this.showExportedFiles(); }, 200);
      return;
    }

    this.lastEvent = setTimeout(() => {
      const that = this;
      let cl: any = [];
      if (this.userModel.role < 3) {
        cl = [
          {title: 'Data zlecenia', data: 'created_at', name: 'ef.created_at'},
          {title: 'Klient', data: 'client_name', name: 'c.name'},
          {title: 'Nazwa', data: 'name', name: 'ef.name'},
          {title: 'Status', data: 'status', name: 'ef.status'}
        ];
      } else {
        cl = [
          {title: 'Data zlecenia', data: 'created_at', name: 'ef.created_at'},
          {title: 'Nazwa', data: 'name', name: 'ef.name'},
          {title: 'Status', data: 'status', name: 'ef.status'}
        ];
      }

      if (!this.dtInit) {
        setTimeout(
          () => {
            this.dtTrigger.next();
          }, 0
        );
      } else {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          try {
            dtInstance.destroy();
            this.dtTrigger.next();
            this.dtInit = false;
          } catch (e) {
          }
        });
      }

      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: 10,
        serverSide: true,
        processing: true,
        ordering: true,
        order: [
          [1, 'DESC']
        ],
        language: this.dtTranslation,
        columns: cl,
        ajax: (dataTablesParameters: any, callback) => {
          that.http.post(
            this.apiUrl + 'app/v1/export/latest/',
            {
              ...dataTablesParameters,
            }
          ).subscribe((resp: any) => {
            this.timeout = 900;
            that.exports = resp.data;
            that.dtInit = true;
            this.lastEvent = null;
            that.lastEventProcessing = false;
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: [],
            });
          })
      }};
    });
  }


  fetchExportsData(reload = false) {
    const that = this;

    let cl: any = [];
    if (this.userModel.role < 3) {
      cl = [
        {title: 'Data zlecenia', data: 'created_at', name: 'ef.created_at'},
        {title: 'Klient', data: 'client_name', name: 'c.name'},
        {title: 'Nazwa', data: 'name', name: 'ef.name'},
        {title: 'Status', data: 'status', name: 'ef.status'}
      ];
    } else {
      cl = [
        {title: 'Data zlecenia', data: 'created_at', name: 'ef.created_at'},
        {title: 'Nazwa', data: 'name', name: 'ef.name'},
        {title: 'Status', data: 'status', name: 'ef.status'}
      ];
    }

    if (reload) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtInit = false;
      });
    }

    if (!this.dtInit) {
      setTimeout(
        () => {
          this.dtTrigger.next();
        }, 0
      );
    } else {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtInit = false;
        setTimeout(
          () => {
            this.dtTrigger.next();
          }, 100
        );
      });
    }

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      processing: true,
      ordering: true,
      order: [
        [1, 'DESC']
      ],
      language: this.dtTranslation,

      ajax: (dataTablesParameters: any, callback) => {
        that.http.post(
          this.apiUrl + 'app/v1/export/latest/',
          {
            ...dataTablesParameters,
          }
        ).subscribe((resp: any) => {

          // for (const redExport of resp.data) {
          //   for (const listedExport of that.exports) {
          //     if (
          //       'downloaded' !== redExport.status
          //       && redExport.identifier === listedExport.identifier
          //       && redExport.status !== listedExport.status
          //     ) {
          //       switch (redExport.status) {
          //         case 'validated':
          //           this.toastService.showMessage('Twoje zestawienie ' + listedExport.name + ' jest gotowe do pobrania!', 's');
          //           break;
          //         case 'validation_error':
          //           this.toastService.showMessage('Wystąpił błąd walidacji twojego zestawienia ' + listedExport.name + '!', 'w');
          //           break;
          //         case 'internal_error':
          //           this.toastService.showMessage('Wystąpił błąd wewnętrzny podczas generowania twojego zestawienia ' + listedExport.name + '!', 'e');
          //           break;
          //       }
          //     }
          //   }
          // }

          that.exports = resp.data;
          this.dtInit = true;

          for (let i = 0; i < resp.data.length; i++) {

            if (Array.isArray(resp.data[i].error)) {
              resp.data[i].error = resp.data[i].error.map(errorItem => {
                return {
                  type: errorItem.type,
                };
              });
            }

            if (resp.data[i].error !== '') {

              const cleanedString = resp.data[i].error
                .replace(/&quot;/g, '"')
                .replace(/&#039;/g, "'");

              resp.data[i].error = JSON.parse(cleanedString);
            }
          }

          callback({
            recordsTotal: resp.recordsTotal,
            recordsFiltered: resp.recordsFiltered,
            data: [],
          });

        }, (error) => {
          console.error('Błąd podczas pobierania danych:', error);
        });
      },

      columns: cl,
    };
  }

  download(ex) {
    if (ex.status === 'validated' || ex.status === 'validation_error' || ex.status === 'downloaded' || ex.status === 'generated') {
      this.parcelService.getPublicFileToken().subscribe(
        (tokenResponse: any) => {
          window.open(
            this.apiUrl
            + 'app/v1/export/download/'
            + ex.identifier
            + '?_public_token='
            + tokenResponse.public_token
          );

          UIkit.modal('#export-list-modal').hide();
        }
      );

      return;
    }

    this.toastService.showMessage('Pobranie pliku nie jest możliwe!', 'w');
  }

  handleRowClick(ex: any) {
    if (this.userModel.role === 3) {
      this.download(ex);
    }
    if (null === ex.errors_count) {
      this.download(ex);
    } else {
      this.exportService.findValidationErrorsByUuid(ex.identifier).subscribe(
        (response: any) => {
          ex.validation_errors = response;
          this.toggleAccordion(ex);
        }
      );
    }
  }

  toggleAccordion(item: any) {
    this.isOpen = this.isOpen === item ? null : item;
  }

  isErrorObject(error: any): boolean {
    return error !== null && typeof error === 'object' && !(error instanceof Array);
  }

  countryNames = {
    1: "Rumunia",
    2: "Polska",
    3: "Węgry",
    4: "Bułgaria"
  };

  findCountryName(id) {
    return this.countryNames[id];
  }

  ngOnDestroy() {
    this.exports = [];

    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtInit = false;
    });

    if (!this.dtTrigger.closed) {
      this.dtTrigger.unsubscribe();
    }
  }

  openParcel(error) {
    if (1 > error.parcelInternalNumbers.length) {
      return;
    }

    window.open('parcel/' + error.parcelInternalNumbers[0], '_blank');
  }

  reviewValidationError(error) {
    this.exportService.reviewValidationError(error.validationErrorId, error.reviewed).subscribe(
      (response: any) => {
        this.toastService.showMessage('Odznaczono poprawnie!', 's', 950);
      }
    );
  }

  protected readonly UserModel = UserModel;
  protected readonly Object = Object;
}

