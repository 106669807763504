import {Routes} from '@angular/router';
import {UsersComponent} from './app/components/pages/users/users.component';
import {AuthGuardGuard} from './app/guards/auth-guard.guard';
import {LoginComponent} from './app/components/pages/login/login.component';
import {UserComponent} from './app/components/pages/user/user.component';
import {ClientsComponent} from './app/components/pages/clients/clients.component';
import {ClientComponent} from './app/components/pages/client/client.component';
import {UserNewComponent} from './app/components/pages/user-new/user-new.component';
import {ClientNewComponent} from './app/components/pages/client-new/client-new.component';
import {ParcelNewComponent} from './app/components/pages/parcel-new/parcel-new.component';
import {ParcelComponent} from './app/components/pages/parcel/parcel.component';
import {ProfileComponent} from './app/components/pages/profile/profile.component';
import {PasswordRecoverComponent} from './app/components/pages/password-recover/password-recover.component';
import {PricesComponent} from './app/components/pages/prices/prices.component';
import {ParcelPublicComponent} from './app/components/pages/parcel-public/parcel-public.component';
import {StatusesComponent} from './app/components/pages/statuses/statuses.component';
import {StatusComponent} from './app/components/pages/status/status.component';
import {PriceComponent} from './app/components/pages/price/price.component';
import {MailCheckComponent} from './app/components/pages/mail-check/mail-check.component';
import {ClientCompanyDataComponent} from './app/components/pages/client-company-data/client-company-data.component';
import {ApiSettingsComponent} from './app/components/pages/api-settings/api-settings.component';
import {TrackingManagerComponent} from './app/components/pages/tracking-manager/tracking-manager.component';
import {ProblemRulesComponent} from './app/components/pages/problem-rules/problem-rules.component';
import {ProblemRuleComponent} from './app/components/pages/problem-rule/problem-rule.component';
import {ProblemsComponent} from './app/components/pages/problems/problems.component';
import {ProblemComponent} from './app/components/pages/problem/problem.component';
import {DaysComponent} from './app/components/pages/days/days.component';
import {IaiSettingsComponent} from './app/components/pages/iai-settings/iai-settings.component';
import {StatsComponent} from './app/components/pages/stats/stats.component';
import {ClientCredentailComponent} from './app/components/pages/client/client-credentail/client-credentail.component';
import {ClientPriceComponent} from './app/components/pages/client/client-price/client-price.component';
import {ParcelFromNumberComponent} from './app/components/pages/parcel-from-number/parcel-from-number.component';
import {
  ClientAdditionalParameterComponent
} from './app/components/pages/client/client-additional-parameter/client-additional-parameter.component';
import {
  OperationalPriceListComponent
} from './app/components/pages/operational-price-list/operational-price-list.component';
import {
  OperationalPriceListFormComponent
} from './app/components/pages/operational-price-list-form/operational-price-list-form.component';
import {
  ParcelReportedResignationsListComponent
} from './app/components/pages/parcel_reported_resignations/parcel-reported-resignations-list/parcel-reported-resignations-list.component';
import {
  ParcelReportedResignationComponent
} from './app/components/pages/parcel_reported_resignations/parcel-reported-resignation/parcel-reported-resignation.component';
import {ParcelsListComponent} from './app/components/pages/parcels-list/parcels-list.component';
import {
  ParcelClassificationComponent
} from "./app/components/pages/parcel-classification/parcel-classification.component";
import {ParcelScanSearchComponent} from "./app/components/pages/parcel-scan-search/parcel-scan-search.component";
import {
  UnknownClientAliasesComponent
} from "./app/components/pages/unknown-client-aliases/unknown-client-aliases.component";
import {UnknownClientAliasComponent} from "./app/components/pages/unknown-client-alias/unknown-client-alias.component";
import {
  MyParcelReportedResignationsListComponent
} from "./app/components/pages/client-role/my-parcel-reported-resignations/my-parcel-reported-resignations-list/my-parcel-reported-resignations-list.component";
import {
  MyParcelReportedResignationComponent
} from "./app/components/pages/client-role/my-parcel-reported-resignations/my-parcel-reported-resignation/my-parcel-reported-resignation.component";
import {
  ClientExternalApiCredentialsDetialsComponent
} from './app/components/pages/client/client-external-api-credentials-detials/client-external-api-credentials-detials.component';
import {
  ClientNewExternalApiCredentialsComponent
} from './app/components/pages/client-new-external-api-credentials/client-new-external-api-credentials.component';
import {FindPostalCodeComponent} from "./app/components/pages/public/find-postal-code/find-postal-code.component";
import {RoleGuard} from './app/guards/role-guard.guard';
import {UserRole} from './app/enums/user-role';
import {InvoicesComponent} from "./app/components/pages/invoice/invoices/invoices.component";
import {InvoiceComponent} from "./app/components/pages/invoice/invoice/invoice.component";


export const appRoutes: Routes = [
    {path: 'find-my-postal-code', component: FindPostalCodeComponent},
    {path: '', component: LoginComponent},
    {path: 'password-recover', component: PasswordRecoverComponent},
    {path: 'tracking', component: ParcelPublicComponent},
    {path: 'tracking/:id', component: ParcelPublicComponent},
    {path: 'users', component: UsersComponent, canActivate: [AuthGuardGuard, RoleGuard], data: { expectedRole: UserRole.admin}},
    {path: 'users/new', component: UserNewComponent, canActivate: [AuthGuardGuard, RoleGuard], data: { expectedRole: UserRole.admin}},
    {path: 'clients', component: ClientsComponent, canActivate: [AuthGuardGuard, RoleGuard], data: { expectedRole: UserRole.admin }},
    {path: 'clients/new', component: ClientNewComponent, canActivate: [AuthGuardGuard, RoleGuard], data: { expectedRole: UserRole.admin}},
    {path: 'users/user/:id', component: UserComponent, canActivate: [AuthGuardGuard, RoleGuard], data: { expectedRole: UserRole.admin}},
    {path: 'client/:id', component: ClientComponent, canActivate: [AuthGuardGuard, RoleGuard], data: { expectedRole: UserRole.admin}},
    {path: 'client/:id/card/:card', component: ClientComponent, canActivate: [AuthGuardGuard, RoleGuard], data: { expectedRole: UserRole.admin}},
    {path: 'client/:idClient/credentail/:id', component: ClientCredentailComponent, canActivate: [AuthGuardGuard, RoleGuard], data: { expectedRole: UserRole.admin}},
    {path: 'client/:idClient/credentail', component: ClientCredentailComponent, canActivate: [AuthGuardGuard, RoleGuard], data: { expectedRole: UserRole.admin}},
    {path: 'client/:idClient/parameter/:id', component: ClientAdditionalParameterComponent, canActivate: [AuthGuardGuard, RoleGuard], data: { expectedRole: UserRole.admin}},
    {path: 'client/:idClient/parameter', component: ClientAdditionalParameterComponent, canActivate: [AuthGuardGuard, RoleGuard], data: { expectedRole: UserRole.admin}},
    {path: 'client/:idClient/price/:id', component: ClientPriceComponent, canActivate: [AuthGuardGuard, RoleGuard], data: { expectedRole: UserRole.admin}},
    {path: 'client/:idClient/price', component: ClientPriceComponent, canActivate: [AuthGuardGuard, RoleGuard], data: { expectedRole: UserRole.admin}},
    {path: 'client/:clientId/external_api_credentials/:id', component: ClientExternalApiCredentialsDetialsComponent, canActivate: [AuthGuardGuard, RoleGuard], data: { expectedRole: UserRole.admin}},
    {path: 'client/:clientId/external_api_credentials', component: ClientNewExternalApiCredentialsComponent, canActivate: [AuthGuardGuard, RoleGuard], data: { expectedRole: UserRole.admin}},
   // {path: 'dashboard', component: ParcelsComponent, canActivate: [AuthGuardGuard]},
   // {path: 'parcels', component: ParcelsComponent, canActivate: [AuthGuardGuard]},
    {path: 'parcels-list', component: ParcelsListComponent, canActivate: [AuthGuardGuard, RoleGuard], data: { expectedRole: [UserRole.admin, UserRole.warehouse_worker] }},
    {path: 'parcel/new', component: ParcelNewComponent, canActivate: [AuthGuardGuard, RoleGuard], data: { expectedRole: [UserRole.admin, UserRole.client] }},
    {path: 'parcel/new/:internalNumber', component: ParcelNewComponent, canActivate: [AuthGuardGuard, RoleGuard], data: { expectedRole: UserRole.admin }},
    {path: 'parcel/return', component: ParcelNewComponent, canActivate: [AuthGuardGuard, RoleGuard], data: { expectedRole: [UserRole.admin, UserRole.client] }},
    {path: 'parcel/:id', component: ParcelComponent, canActivate: [AuthGuardGuard, RoleGuard], data: { expectedRole: [UserRole.admin, UserRole.warehouse_worker, UserRole.client] }},
    {path: 'profile', component: ProfileComponent, canActivate: [AuthGuardGuard, RoleGuard], data: { expectedRole: [UserRole.admin, UserRole.warehouse_worker, UserRole.client] }},
    {path: 'prices', component: PricesComponent, canActivate: [AuthGuardGuard, RoleGuard], data: { expectedRole: UserRole.admin }},
    {path: 'prices/price/:id', component: PriceComponent, canActivate: [AuthGuardGuard, RoleGuard], data: { expectedRole: UserRole.admin}},
    {path: 'email/check/:token', component: MailCheckComponent},
    {path: 'statuses', component: StatusesComponent, canActivate: [AuthGuardGuard, RoleGuard], data: { expectedRole: UserRole.admin}},
    {path: 'statuses/status/:id', component: StatusComponent, canActivate: [AuthGuardGuard, RoleGuard], data: { expectedRole: UserRole.admin}},
    {path: 'status/new', component: StatusComponent, canActivate: [AuthGuardGuard, RoleGuard], data: { expectedRole: UserRole.admin}},
    {path: 'company', component: ClientCompanyDataComponent, canActivate: [AuthGuardGuard, RoleGuard], data: {expectedRole: [UserRole.admin, UserRole.client]}},
    {path: 'api_settings', component: ApiSettingsComponent, canActivate: [AuthGuardGuard, RoleGuard], data: { expectedRole: UserRole.client}},
    {path: 'iai_settings', component: IaiSettingsComponent, canActivate: [AuthGuardGuard, RoleGuard], data: { expectedRole: UserRole.admin}},
    {path: 'tracking-manager', component: TrackingManagerComponent, canActivate: [AuthGuardGuard, RoleGuard], data: { expectedRole: UserRole.admin}},
    {path: 'problem-rules', component: ProblemRulesComponent, canActivate: [AuthGuardGuard, RoleGuard], data: { expectedRole: UserRole.admin }},
    {path: 'problem-rule/:id', component: ProblemRuleComponent, canActivate: [AuthGuardGuard, RoleGuard], data: { expectedRole: UserRole.admin }},
    {path: 'problem-rule', component: ProblemRuleComponent, canActivate: [AuthGuardGuard, RoleGuard], data: { expectedRole: UserRole.admin }},
    {path: 'problems', component: ProblemsComponent, canActivate: [AuthGuardGuard, RoleGuard], data: { expectedRole: UserRole.admin }},
    {path: 'problem/:id', component: ProblemComponent, canActivate: [AuthGuardGuard, RoleGuard], data: { expectedRole: UserRole.admin }},
    {path: 'stats', component: StatsComponent, canActivate: [AuthGuardGuard, RoleGuard], data: { expectedRole: UserRole.admin}},
    {path: 'calendar', component: DaysComponent, canActivate: [AuthGuardGuard, RoleGuard], data: { expectedRole: UserRole.admin}},

    // new
    {path: 'parcel-from-number', component: ParcelFromNumberComponent, canActivate: [AuthGuardGuard, RoleGuard], data: { expectedRole: [UserRole.admin, UserRole.warehouse_worker] }},
    {path: 'parcel-classification', component: ParcelClassificationComponent, canActivate: [AuthGuardGuard, RoleGuard], data: { expectedRole: [UserRole.admin, UserRole.warehouse_worker] }},
    {path: 'operational-price-list/:type/list', component: OperationalPriceListComponent, canActivate: [AuthGuardGuard, RoleGuard], data: { expectedRole: UserRole.admin}},
    {path: 'operational-price-list/:type/form', component: OperationalPriceListFormComponent, canActivate: [AuthGuardGuard, RoleGuard], data: { expectedRole: UserRole.admin}},
    {path: 'operational-price-list/:type/form/:id', component: OperationalPriceListFormComponent, canActivate: [AuthGuardGuard, RoleGuard], data: { expectedRole: UserRole.admin}},
  // tslint:disable-next-line:max-line-length
    {path: 'operational-price-list/:type/client_form/:idClient', component: OperationalPriceListFormComponent, canActivate: [AuthGuardGuard, RoleGuard], data: { expectedRole: UserRole.admin}},
  // tslint:disable-next-line:max-line-length
    {path: 'operational-price-list/:type/client_form/:idClient/:id', component: OperationalPriceListFormComponent, canActivate: [AuthGuardGuard, RoleGuard], data: { expectedRole: UserRole.admin}},
    {path: 'parcel-reported-resignations', component: ParcelReportedResignationsListComponent, canActivate: [AuthGuardGuard, RoleGuard], data: { expectedRole: UserRole.admin}},
    {path: 'parcel-reported-resignation', component: ParcelReportedResignationComponent, canActivate: [AuthGuardGuard, RoleGuard], data: { expectedRole: UserRole.admin}},
    {path: 'parcel-reported-resignation/:id', component: ParcelReportedResignationComponent, canActivate: [AuthGuardGuard, RoleGuard], data: { expectedRole: UserRole.admin}},

    {path: 'parcel-list', component: ParcelsListComponent, canActivate: [AuthGuardGuard, RoleGuard], data: { expectedRole: [UserRole.admin, UserRole.warehouse_worker] }},
    {path: 'dashboard', component: ParcelsListComponent, canActivate: [AuthGuardGuard, RoleGuard], data: { expectedRole: [UserRole.admin, UserRole.warehouse_worker, UserRole.client] }}, //todo ujednolicić
    {path: 'parcels', component: ParcelsListComponent, canActivate: [AuthGuardGuard, RoleGuard], data: { expectedRole: [UserRole.admin, UserRole.warehouse_worker, UserRole.client] }}, //todo ujednolicić
    {path: 'parcel-search', component: ParcelScanSearchComponent, canActivate: [AuthGuardGuard, RoleGuard], data: { expectedRole: [UserRole.admin, UserRole.warehouse_worker, UserRole.client] }},
    {path: 'unknown-client-aliases', component: UnknownClientAliasesComponent, canActivate: [AuthGuardGuard, RoleGuard], data: { expectedRole: UserRole.admin}},
    {path: 'unknown-client-aliases/:id', component: UnknownClientAliasComponent, canActivate: [AuthGuardGuard, RoleGuard], data: { expectedRole: UserRole.admin}},

    {path: 'invoices', component: InvoicesComponent, canActivate: [AuthGuardGuard, RoleGuard], data: { expectedRole: UserRole.admin}},
    {path: 'invoices/:id', component: InvoiceComponent, canActivate: [AuthGuardGuard, RoleGuard], data: { expectedRole: UserRole.admin}},

    //client-role
    {
      path: 'my-parcel-reported-resignations',
      component: MyParcelReportedResignationsListComponent,
      canActivate: [AuthGuardGuard, RoleGuard], data: { expectedRole: UserRole.client}
    },
    {
      path: 'my-parcel-reported-resignation/:id',
      component: MyParcelReportedResignationComponent,
      canActivate: [AuthGuardGuard, RoleGuard], data: { expectedRole: UserRole.client}
    },
    {
      path: 'my-parcel-reported-resignation',
      component: MyParcelReportedResignationComponent,
      canActivate: [AuthGuardGuard, RoleGuard], data: { expectedRole: UserRole.client}
    },
];
