import {Component, OnInit} from '@angular/core';
import {ClientsService} from '../../../services/clients/clients.service';
import {ToastsService} from '../../../services/toasts.service';
import {Router} from '@angular/router';
import {CountryService} from "../../../services/country/country.service";
import * as test from "node:test";

@Component({
  selector: 'app-client-new',
  templateUrl: './client-new.component.html',
  styleUrls: ['./client-new.component.scss']
})
export class ClientNewComponent implements OnInit {

  constructor(
    private clientsService: ClientsService,
    private toastsService: ToastsService,
    private route: Router,
    private countryService: CountryService,
  ) {
  }

  defaults = {
    dpd_system_id: '',
    dpd_api_password: '',
    out_service: '',
    iai_password: '',
  };

  defaultValueReReturningAddress =
    'Romania Express - .......\n' +
    'Depozit Sameday Arad\n' +
    'Str. Bodrogului F/N\n' +
    'Arad, kod: 310059';

  defaultReBroadcastingFacilityAddress =
    'Romania Express\n' +
    'Fabryczna 12\n' +
    '43-100 Tychy\n' +
    'e-mail (dla kuriera): magazyn-tychy@romaniaexpress.pl\n' +
    'nr tel. (dla kuriera): 880-399-503';

  contactName = 'pentru: ';
  addressAdditionalInfo = '-';
  bankAccountNumber = 'n/d';
  shipmentNote = 'Trimis de: ';
  phoneNumberPrefix = '+48';
  defaultNo = 0;
  countries = [];

  ngOnInit() {
    this.setDefaultCredentails(true);
    this.countryService.list().subscribe(
      (countriesResponse: any) => {
        for (let country of countriesResponse) {
          if (country.iso_code !== 'PL') {
            country.checked = true;
            this.countries.push(country);
          }
        }
      }
    );
  }

  changeAllowedCountry(index) {
    this.countries[index].checked = !this.countries[index].checked;
  }

  onCreateClient(form) {
    form.value.allowed_countries = [];
    for (let country of this.countries) {
      if (country.checked) {
        form.value.allowed_countries.push(country.id);
      }
    }

    this.clientsService.createClient(form.value).subscribe(
      (s) => {
        this.toastsService.showMessage('client_added', 's');
        this.route.navigateByUrl('/clients');
      }
    );
  }

  setDefaultCredentails(silent=false) {
    this.clientsService.askCredenials().subscribe(
      (response: any) => {
        this.defaults = response;
        if(!silent) {
          this.toastsService.showMessage('Domyślne dane dostępowe zostały wprowadzone!', 's');
        }
      }
    );
  }

  generateIAIPassword() {
    this.clientsService.generateIAIPassword().subscribe(
      (response: any)=>{
        this.defaults.iai_password = response.password;
      }
    );
  }

}
