<div class="sidebar computer-only">
  <div>
    <div class="mb-1">
      <div class="">
        <img src="/assets/img/re-logo.png" class="uk-align-center uk-padding-top logo sidebar-logo-size">
      </div>
    </div>
    <div class="uk-width-1-1 uk-m mb-1">
      <div>
        <h4 class="uk-text uk-text-muted uk-text-center uk-text-small">
          {{userModel.email}}
        </h4>
      </div>
    </div>
    <!--
    <a class="uk-button uk-button-primary uk-width-1-1 mb-1" routerLink="/dashboard">
      {{'parcels' | translate}}
    </a>-->
    <a class="uk-button uk-button-primary uk-width-1-1 mb-1" routerLink="/parcel-list">
      {{'parcels' | translate}}
    </a>
    <a class="uk-button uk-button-primary uk-width-1-1 mb-1" routerLink="/parcel-from-number">
      {{'create_parcel_from_number' | translate}}
    </a>
    <a class="uk-button uk-button-primary uk-width-1-1 mb-1" routerLink="/parcel-classification">
      {{'parcel_classification' | translate}}
    </a>
    <a class="uk-button uk-button-primary uk-width-1-1 mb-1" routerLink="/parcel-search">
      {{'parcel_scan_search' | translate}}
    </a>
    <a class="uk-button uk-button-primary uk-width-1-1 mb-1" routerLink="/profile">
      {{'change_password' | translate}}
    </a>
    <a class="uk-button uk-button-danger uk-width-1-1" (click)="onLogout()">
      {{'logout' | translate}}
    </a>
  </div>
</div>

<div class="sidebar mobile-only">
  <div class="">
    <div class="" style="margin-bottom: 1px; margin-top: 10px">
      <div class=" ">
        <img src="/assets/img/re-logo.png" class="uk-align-center uk-padding-top logo pt-10">
      </div>
    </div>
    <div class="uk-width-1-1 mb-1">
      <div>
        <h4 class="uk-text uk-text-muted uk-text-center">{{userModel.email}}</h4>
      </div>
    </div>
    <a class="uk-button uk-button-primary uk-width-1-1 mb-1" (click)="mobileMenuIsActive = !mobileMenuIsActive">
      {{'Menu' | translate}}
    </a>
    <div class="uk-width-1-1" [class.uk-hidden]="!mobileMenuIsActive">
      <a class="uk-button uk-button-primary uk-width-1-1 mb-1" routerLink="/dashboard">
        {{'parcels' | translate}}
      </a>
      <a class="uk-button uk-button-primary uk-width-1-1 mb-1" routerLink="/tracking-manager">
        {{'manage_parcel_statuses' | translate}}
      </a>
      <a class="uk-button uk-button-primary uk-width-1-1 mb-1" routerLink="/profile">
        {{'change_password' | translate}}
      </a>
      <a class="uk-button uk-button-primary uk-width-1-1 mb-1"  routerLink="/parcel-search">
        {{'parcel_scan_search' | translate}}
      </a>
      <a class="uk-button uk-button-danger uk-width-1-1" (click)="onLogout()">{{'logout' | translate}}</a>
    </div>
  </div>
</div>
