import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastsService} from '../../../../services/toasts.service';
import {
  ClientAdditionalParametersService
} from '../../../../services/client-additional-parameters/client-additional-parameters.service';
import {ClientsService} from '../../../../services/clients/clients.service';
declare var UIkit: any;
@Component({
  selector: 'app-client-additional-parameter',
  templateUrl: './client-additional-parameter.component.html',
  styleUrls: ['./client-additional-parameter.component.scss']
})
export class ClientAdditionalParameterComponent implements OnInit {
  id = null;
  idClient = null;
  code = null;
  types = [];
  type = {
    code: null,
    id: null,
  };
  model = {
    client_id: null,
    type_id: null,
    value: null,
    hidden_value: null,
    name: null,
    code: null,
    extra_parameters: null,
    extra_parameters_inputs: []
  };

  searchText = '';
  searchOptions = [];

  extra_parameters = [];

  constructor(
    private clientAdditionalParametersService: ClientAdditionalParametersService,
    private clientsService: ClientsService,
    private toastsService: ToastsService,
    private route: ActivatedRoute,
    private router: Router
  ) {
  }

  getTypes(){
    this.clientAdditionalParametersService.getTypes().subscribe(
      (types: any) => {
        this.types = types;
      }
    );
  }

  // tslint:disable-next-line:variable-name
  getType(type_id) {
    this.model.extra_parameters = [];
    this.clientAdditionalParametersService.findType(type_id).subscribe(
      (type: any) => {
        this.type = type;
        console.log(type);

        this.model.extra_parameters = type.extra_parameters;
        if (['USE_REAL_WEIGHT_FOR_ROMANIA_SHIPMENTS_ACCOUNTING', 'USE_REAL_WEIGHT_FOR_HUNGARY_SHIPMENTS_ACCOUNTING', 'USE_REAL_WEIGHT_FOR_BULGARIA_SHIPMENTS_ACCOUNTING', 'SENDING_SHIPMENTS_BLOCK'].includes(type.code)) {
          this.model.value = true;
        }
      }
    );
  }

  showItem(){
    this.clientAdditionalParametersService.findByIdclient(this.idClient, this.id).subscribe(
      (response: any) => {
        this.model = response;
        this.getType(response.type_id);
      }
    );
  }

  ngOnInit(): void {
    try {
      this.idClient = this.route.snapshot.paramMap.get('idClient');
      const id = this.route.snapshot.paramMap.get('id');
      if (id !== undefined) {
        this.id = id;
      }
    } catch (error) {
      this.id = null;
    }
    this.getTypes();
    if (this.id !== null) {
      this.showItem();
    }
  }

  submit() {
    if (this.id === null) {
      this.create();
    } else {
      this.update();
    }
  }

  setTypeCode(code) {
    this.code = code;
  }

  create(){
    this.model.client_id = this.idClient;
    this.clientAdditionalParametersService.store(this.idClient, this.model).subscribe(
      (response: any) => {
        this.router.navigateByUrl('/client/' + this.idClient + '/card/parameters').then(r => {
          this.toastsService.showMessage('Pomyślnie zdefiniowanio dodatkowy parametr', 's');
        });
      });
  }

  update() {
    this.model.client_id = this.idClient;
    this.clientAdditionalParametersService.update(this.idClient, this.model).subscribe(
      (response: any) => {
        this.toastsService.showMessage('Zmiany zostały zapisane!', 's');
      }
    );
  }

  async delete(){
    const promised = await this.toastsService.showConfirmModal();
    if (!promised) {
      return;
    }

    this.clientAdditionalParametersService.delete(this.idClient, this.id).subscribe(
      (response: any) => {
        this.router.navigateByUrl('/client/' + this.idClient + '/card/parameters').then(r => {
          this.toastsService.showMessage('Dane dostępowe zostały pomyślie usunięte!', 's');
        });
      }
    );
  }

  searchContact(courier, input) {
    this.searchText = input.value;
    this.clientsService.searchForSmdContact(courier, input.value).subscribe(
      (contacts: Array<any>) => {
        this.searchOptions = contacts;
      }
    );
  }

  setContact(contact) {
    this.searchText = contact.name;
    this.model.hidden_value = contact.id;
    this.model.value = contact.name;
    this.searchOptions = [];
  }
}
