import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClientsService {
  private apiurl = '';

  constructor(private http: HttpClient, private router: Router) {
    this.apiurl = environment.api;
  }

  findPeriod(idClient) {
    const url = this.apiurl + 'app/v1/client/findPeriod/' + idClient;

    return this.http.get(url);
  }

  createClient(form) {
    const url = this.apiurl + 'client';
    return this.http.post(url, form);
  }

  generateIAIPassword() {
    const url = this.apiurl + 'client/iai/password';
    return this.http.get(url);
  }

  getClient(id) {
    const url = this.apiurl + 'client/data/' + id;
    return this.http.get(url);
  }

  getMyData() {
    const url = this.apiurl + 'client/data/0';
    return this.http.get(url);
  }

  searchForSmdContact(courier, query) {
    const url = this.apiurl + 'app/v1/courier/' + courier + '/search/contacts?query=' + encodeURI(query);
    return this.http.get(url);
  }

  getClients() {
    const url = this.apiurl + 'clients';
    return this.http.get(url);
  }

  getPrices(id) {
    const url = this.apiurl + 'client/prices/' + id;
    return this.http.get(url);
  }
  getPrice(id) {
    const url = this.apiurl + 'price/' + id;
    return this.http.get(url);
  }
  searchClient(query) {
    const url = this.apiurl + 'client/search/' + encodeURI(query);
    return this.http.get(url);
  }

  updateClient(form) {
    const url = this.apiurl + 'app/v1/client/';
    return this.http.put(url, form, {observe: 'response'});
  }

  getClientNavigation(id): Observable<{ previousClientId: number, nextClientId: number }> {
    const url = this.apiurl + 'app/v1/client/' + id +'/navigation';
    return this.http.get<{ previousClientId: number, nextClientId: number }>(url);
  }

  getClientShipmentsInPerioid(id){
    const url = this.apiurl + 'app/v1/client/' + id + '/shipments_count';
    return this.http.get(url);
  }

  updateIAISettings(form) {
    const url = this.apiurl + 'client/iai';
    return this.http.put(url, form);
  }

  changePrice(form) {
    const url = this.apiurl + 'client/prices';
    return this.http.put(url, form);
  }

  removeClient(id) {
    const url = this.apiurl + 'client';
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        id
      },
    };
    return this.http.delete(url, options);
  }
  changeApiEnabled(id) {
    const url = this.apiurl + 'client/api';
    return this.http.put(url, {id});
  }

  changeAPIAllowedIP(form) {
    const url = this.apiurl + 'client/api/ip';
    return this.http.put(url, form);
  }

  generateNewAPIToken() {
    const url = this.apiurl + 'client/api/token';
    return this.http.put(url, {});
  }

  deletePrice(id){
    const url = this.apiurl + 'client/prices';
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        id
      },
    };
    return this.http.delete(url, options);
  }

  addPrice(form){
    const url = this.apiurl + 'client/prices';
    return this.http.post(url, form);
  }

  askCredenials() {
    const url = this.apiurl + 'ask/credentials/DPD';
    return this.http.get(url);
  }

  findClientCountries(clientId) {
    return this.http.get( this.apiurl + 'app/v1/client/' + clientId + '/allowed_countries');
  }
}
