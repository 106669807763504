import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ParcelsService} from '../../../services/parcels/parcels.service';
import {ToastsService} from '../../../services/toasts.service';
import {environment} from '../../../../environments/environment';
import {ClientsService} from '../../../services/clients/clients.service';
import {ScanningService} from '../../../services/scanning/scanning.service';
import {ParcelService} from '../../../services/parcel/parcel.service';
import {ClassificationService} from '../../../services/classification/classification.service';

@Component({
  selector: 'app-parcel-classification',
  templateUrl: './parcel-classification.component.html',
  styleUrls: ['./parcel-classification.component.scss']
})
export class ParcelClassificationComponent implements OnInit, OnDestroy {
  @ViewChild('barcodeInput') barcodeInput: ElementRef;
  internalNumber = null;

  apiurl = null;
  id = null;
  statuses = [];
  error = false;

  idClient = null;
  client = null;

  scanningLocked = false;

  public lastChars = '';
  public lastKeyCode = null;
  public clearLastCharsInterval = null;
  statusTypes = [];
  barcode = '';

  constructor(
      private toastsService: ToastsService,
      private classificationService: ClassificationService
  ) {
    this.apiurl = environment.api;
  }

  ngOnInit() {
    this.keyReading();
    this.clearLastChars();
  }

  runCourierClassification() {
    this.classificationService.classifyByCourier({barcode: this.barcode}).subscribe(
      (response: any) => {
        if (response.body.completed_multi_pack_waybill) {
          this.toastsService.showMultiPackIsCompletedMessage(
            response.body.completed_multi_pack_waybill,
            15000
          );
        }
        this.toastsService.showSortingMessage(response.body.bucket.name, response.body.bucket.identifier, 1500);

      },
      (error: any) => {
        switch (error.status) {
          case 402:
            this.toastsService.showWrongClientAliasParcel(3000);
            break;
          case 406:
            this.toastsService.showSortingMessage(false, null, 1500);
            break;
          case 410:
            this.toastsService.showDeletedParcelMessage();
            break;
          case 424:
            this.toastsService.showNotFullyScannedMultiPackMessage(3000);
            break;
          case 425:
            this.toastsService.showTooEarlyScannedMessage(3000);
            break;
          case 403:
            this.toastsService.showBlockDispatchMessage(5000);
            break;
          default:
            this.toastsService.showSortingMessage(null, null, 1500);
            break;
        }
      }
    );
  }

  ngOnDestroy() {
    document.removeEventListener('keyup', this.listenerAction, false);
  }

  keyReading() {
    document.addEventListener('keyup', this.listenerAction, false);
  }


  eanChecker(tempChars) {
    if (tempChars.length === 8) {
      this.toastsService.showMessage('Zeskanowano nieprawidłowy kod - wykryto EAN8', 'w', 5000);
      return false;
    }
    if (tempChars.length === 13) {
      this.toastsService.showMessage('Zeskanowano nieprawidłowy kod - wykryto EAN13', 'w', 5000);
      return false;
    }
    if (tempChars.length === 14) {
      this.toastsService.showMessage('Zeskanowano nieprawidłowy kod - wykryto EAN14', 'w', 5000);
      return false;
    }
    if (tempChars.length > 28) {
      this.toastsService.showMessage('Zbyt szybko skanujesz przesyłki', 'w', 5000);
      return false;
    }

    return true;
  }

  listenerAction = (e) => {
    this.clearLastChars();
    if ((e.ctrlKey || e.metaKey)) {
      return;
    }

    this.lastKeyCode = e.keyCode;

    if ((e.keyCode >= 48 && e.keyCode <= 57 && this.lastKeyCode !== 16)
      || e.keyCode === 189 || (e.keyCode > 40 && e.keyCode < 91)) {
      if (e.keyCode === 189) {
        this.lastChars += '_';
      } else {
        this.lastChars += String.fromCharCode(e.keyCode) + '';
      }
    }

    this.lastKeyCode = e.keyCode;
  }

  checkNumbers() {
    if (this.lastChars.length === 0 || !this.eanChecker(this.lastChars)){
      return;
    }

    this.barcode = this.lastChars;
    this.runCourierClassification();
  }

  clearLastChars() {
    if (this.clearLastCharsInterval != null) {
      clearInterval(this.clearLastCharsInterval);
      this.clearLastCharsInterval = null;
    }
    this.clearLastCharsInterval = setInterval(
      () => {
        this.checkNumbers();
        this.lastChars = '';
      }, 400
    );
  }
}

