import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';
import {environment} from '../../../environments/environment';
import {UserModel} from '../../models/user.model';
import {RegisterModel} from '../../models/register.model';



@Injectable({
    providedIn: 'root'
})
export class AuthService {


    private apiurl = '';
    private userModel = new BehaviorSubject<UserModel>(null);
    isLogged = this.userModel.asObservable();

    changeLoginStatus(isLogged: UserModel) {
        this.userModel.next(isLogged);
    }

    get getToken(): string {
        if (this.userModel.getValue() !== null) {
            return (this.userModel.getValue())._token;
        }
        return '';
    }

    get getRole(): number | null {
      return (this.userModel.getValue()).role;
    }

    constructor(private http: HttpClient, private router: Router) {
        this.apiurl = environment.api;
    }

    public register(user: RegisterModel) {
        const url = this.apiurl + 'user/register';
        return this.http.post(
            url, user
        );
    }

    public login(form) {
        const url = this.apiurl + 'user/login';
        return this.http.post(
            url, form
        );
    }


    public logout() {
        localStorage.removeItem('user');
        this.changeLoginStatus(null);
        this.router.navigate(['/']);
    }

    get isLoggedIn() {
        if (this.userModel === null || 'undefined') {
            const user: any = JSON.parse(localStorage.getItem('user'));
            if (user !== null && user !== 'undefined') {
                this.changeLoginStatus(user);
                return true;
            }
        } else {
            return true;
        }
        return false;
    }

    get user() {
        return this.userModel;
    }

    public setUserModel(user: UserModel) {
        this.changeLoginStatus(user);
        localStorage.setItem('user', JSON.stringify(user));
    }

    updatePassword(form) {
        const url = this.apiurl + 'user/password';
        return this.http.put(url, form);
    }

    recoverPassword(form) {
        const url = this.apiurl + 'user/recover';
        return this.http.post(url, form);
    }

    checkEmail(token) {
        const url = this.apiurl + 'user/confirm/' + token;
        return this.http.get(url);
    }

    whoami() {
        const url = this.apiurl + 'whoami';
        return this.http.get(url);
    }
}
