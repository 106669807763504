import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Router} from "@angular/router";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ClientExternalApiCredentialsService {
  private apiurl = '';
  constructor(private http: HttpClient, private router: Router) {
    this.apiurl = environment.api;
  }

  listByClientId(clientId) {
    const url = this.apiurl + 'app/v1/client/' +  clientId + '/external_api_credentials'

    return this.http.get(url);
  }

  findByIdclient(clientId, id) {
    const url = this.apiurl + 'app/v1/client/' +  clientId + '/external_api_credentials/' +id

    return this.http.get(url);
  }

  store(clientId, form) {
    const url = this.apiurl + 'app/v1/client/' +  clientId + '/external_api_credentials/'

    return this.http.post(url, form);
  }
  update(clientId, form) {
    const url = this.apiurl + 'app/v1/client/' +  clientId + '/external_api_credentials/';

    return this.http.put(url, form);
  }

  delete(clientId, id) {
    const url = this.apiurl + 'app/v1/client/' +  clientId + '/external_api_credentials/'

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        'credentialsId': id,
      },
    };

    return this.http.delete(url, options);
  }

}
