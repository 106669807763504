<script src="../parcel-from-number/parcel-from-number.component.ts"></script>
<div class="uk-grid">
    <div class="uk-width-1-1 navbar">
        <div class="nav-text">
            {{'parcel' | translate}}:
          <span title="Numer wewnętrzny przesyłki">
            <kbd>{{parcel.internal_number}}</kbd>
          </span>

          <img src="{{flagUrl(parcel)}}" style="height: 30px; margin-top: -5px" title="{{country(parcel)}}">

          <button class="uk-button uk-button-primary padding-horizontal-small button-opt"
                  [attr.uk-tooltip]="'Wróć'"
                  [routerLink]="'/parcel-list'"
          >
            <span uk-icon="sign-out"></span>
          </button>

          <button
            *ngIf="userModel.role == 0 && null !== parcel.buffer_number && 1 === parcel.processable"
            class="uk-button uk-button-primary padding-horizontal-small button-opt uk-margin-remove-right"
            [attr.uk-tooltip]="'Wymuś przypisanie do klienta'"
            (click)="openAdoptBufferParcelToClientModal()"
          >
            <span uk-icon="bolt"></span>
          </button>

          <button
            *ngIf="userModel.role == 0 && null !== parcel.buffer_number && 0 === parcel.processable && parcel.out_number == '-' && parcel.in_number == '-'"
            class="uk-button uk-button-primary padding-horizontal-small button-opt uk-margin-remove-right"
            [attr.uk-tooltip]="'Wymuś przypisanie do klienta'"
            (click)="openAdoptUnprocessableParcelToClientModal()"
          >
            <span uk-icon="bolt"></span>
          </button>

          <!--<button class="uk-button uk-button-primary padding-horizontal-small button-opt uk-margin-remove-right" style="margin-right: 0" [attr.uk-tooltip]="'Kopiuj przesyłkę'" [class.uk-margin-remove-right]="parcel.out_number != '-' && parcel.id_out_courier == 2 && userModel.role == 3 && false" (click)="copy(parcel.internal_number)"><span uk-icon="copy"></span></button>-->
          <button *ngIf="!hideOptions && returnLabelAllowed"
                  class="uk-button uk-button-primary padding-horizontal-small button-opt uk-margin-remove-right" style="margin-right: 0"
                  [attr.uk-tooltip]="'Drukuj etykietę'"
                  [class.uk-margin-remove-right]="parcel.out_number != '-'"
                  (click)="openModal('label'); generateProgressAlert = false;">
            <span uk-icon="print"></span>
          </button>
          <button *ngIf="parcel.return_parcel && parcel.in_number != '-' && null === parcel.in_pickup_date && parcel.processable == 1"
                  class="uk-button uk-button-primary padding-horizontal-small button-opt uk-margin-remove-right" style="margin-right: 0"
                  [attr.uk-tooltip]="'Zamów kuriera do odbioru przesyłki'"
                  [class.uk-margin-remove-right]="parcel.out_number != '-'"
                  (click)="openModal('requestPickupModal'); generateProgressAlert = false;">
            <span uk-icon="bell"></span>
          </button>
          <button class="uk-button uk-button-primary padding-horizontal-small button-opt uk-margin-remove-right"
                  style="margin-right: 0" [attr.uk-tooltip]="'Kopiuj link do śledzenia'"
                  [class.uk-margin-remove-right]="parcel.out_number != '-'" (click)="copyTrackingUrl(parcel.internal_number)"
          >
            <span uk-icon="link"></span>
          </button>
          <button class="uk-button uk-button-primary padding-horizontal-small button-opt uk-margin-remove-right"
                  style="margin-right: 0"
                  [attr.uk-tooltip]="'Zmień status'"
                  [class.uk-margin-remove-right]="parcel.out_number != '-'"
                  (click)="openModal('changeStatus');"
                  *ngIf="parcel.deleted_at == null && (userModel.role == 0 || userModel.role == 1)"
          >
            <span uk-icon="commenting"></span>
          </button>
          <button class="uk-button uk-button-danger padding-horizontal-small button-opt uk-margin-remove-right"
                  style="margin-right: 0"
                  [attr.uk-tooltip]="'Anuluj przesyłkę'"
                  [class.uk-margin-remove-right]="parcel.out_number != '-'"
                  (click)="openModal('cancel');"
                  *ngIf="parcel.deleted_at == null && !hideOptions && userModel.role != 1"
          >
            <span uk-icon="ban"></span>
          </button>
          <button class="uk-button uk-button-primary padding-horizontal-small button-opt uk-margin-remove-right"
                  style="margin-right: 0; background-color: var(--warning-re) !important;"
                  [attr.uk-tooltip]="'Przejdź do wykrytej nieprawidłowości'"
                  *ngIf="parcel.deleted_at == null && parcel.problem && userModel.role == 0"
                  (click)="goToLastProblem()"
          >
            <span uk-icon="warning"></span>
          </button>
          <button
            *ngIf="userModel.role == 0 && null === parcel.buffer_number && hideOptions && null === parcel.deleted_at"
            class="uk-button uk-button-danger padding-horizontal-small button-opt uk-margin-remove-right"
            [attr.uk-tooltip]="'Oznacz jako usunietą'"
            (click)="openConfirmMarkAsRemovedModal()"
          >
            <span uk-icon="trash"></span>
          </button>
        </div>
    </div>
  <div *ngIf="parcel.error_message" style="font-size: 15px; text-align: center; width: 80%; margin-top: -30px; margin-bottom: 5px">
            <br>{{parcel.error_message}}
  </div>
   <div class="uk-width-1-1 inner-shadow">
     <div class="uk-card uk-card-default uk-card-body uk-margin-medium-right uk-margin-medium-top">
      <div class="uk-grid">
        <div class="uk-width-1-2@m">
          <form #parcelForm="ngForm" (ngSubmit)="onEditParcel(parcelForm)" autocomplete="off">
            <div class="uk-grid">
              <div class="uk-width-1-1 uk-margin-bottom" *ngIf="userModel.role < 3">
                <div class="uk-width-1-2@m">
                  <label class="">{{'courier_name' | translate}}</label>
                  <input type="text" autocomplete="none" name="xyz_in_number" [(ngModel)]="parcel.courier_name" disabled class="uk-input">
                </div>
                <div class="uk-width-1-2@m">
                  <label class="">{{'out_number' | translate}}</label>
                  <input type="text" autocomplete="none" name="xyz_out_number" value="{{parcel.buffer_number === null ? (parcel.out_number === '-' ? parcel.in_number : parcel.out_number) : parcel.buffer_number}}" disabled class="uk-input">
                </div>
                <div class="uk-width-1-2@m">
                  <label class="">{{'return_number' | translate}}</label>
                  <input type="text" autocomplete="none" name="xyz_return_number" [(ngModel)]="parcel.return_number" disabled class="uk-input">
                </div>
              </div>

              <input  type="hidden" name="id" #id="ngModel" [ngModel]="parcel.id" required>

              <div class="uk-width-1-1 uk-margin-bottom" *ngIf="userModel.role < 3">
                  <p class="uk-heading-divider">
                      {{'client_data' | translate}}
                  </p>
              </div>

              <div class="uk-width-1-2@m"  *ngIf="userModel.role < 2">
                  <label class="required">{{'name' | translate}}</label>
                  <input disabled autocomplete="off" [class.uk-form-danger]="clientInput.touched && id_client.value == ''" (keyup)="searchClient(clientInput)" type="text" #clientInput="ngModel" name="client" [(ngModel)]="parcel.name" class="uk-input" required>
                  <input type="hidden" #id_client="ngModel" autocomplete="none" name="id_client" [(ngModel)]="parcel.id_client"  class="uk-input">
              </div>

              <div class="uk-width-1-1 uk-margin-bottom" [class.uk-margin-top]="userModel.role < 2">
                  <p class="uk-heading-divider">
                      {{'parcel_size' | translate}}
                  </p>
              </div>

                    <div class="uk-width-1-3@m">
                        <label class="required">{{'weight' | translate}}</label>
                        <input [disabled]="(userModel.role == 3)" type="number" step="0.01" min="0" #weight="ngModel" autocomplete="none" name="weight"  [(ngModel)]="parcel.weight" required class="uk-input">
                    </div>

                    <div class="uk-width-1-3@m">
                      <label class="required">{{'volumetric_weight' | translate}}</label>
                      <input disabled type="number" step="0.01" min="0" #volumetric_weight="ngModel" autocomplete="none" name="volumetric_weight"  [(ngModel)]="parcel.volumetric_weight" required class="uk-input">
                    </div>

                    <div class="uk-width-1-3@m">
                      <label class="required">{{'accounted_weight' | translate}}</label>
                      <input disabled type="number" step="0.01" min="0" #accounted_weight="ngModel" autocomplete="none" name="accounted_weight"  [(ngModel)]="parcel.accounted_weight" required class="uk-input">
                    </div>

                    <div class="uk-width-1-3@m">
                        <label class="required">{{'height' | translate}}</label>
                        <input [disabled]="(userModel.role == 3)" type="number" step="0.01" min="0" #height="ngModel" autocomplete="none" name="height"  [(ngModel)]="parcel.height" required class="uk-input">
                    </div>

                    <div class="uk-width-1-3@m">
                        <label class="required">{{'width' | translate}}</label>
                        <input [disabled]="(userModel.role == 3)" type="number" step="0.01" min="0" #width="ngModel" autocomplete="none" name="width"  [(ngModel)]="parcel.width" required class="uk-input">
                    </div>

                    <div class="uk-width-1-3@m">
                        <label class="required">{{'length' | translate}}</label>
                        <input [disabled]="(userModel.role == 3)"  type="number" step="0.01" min="0" #length="ngModel" autocomplete="none" name="length"  [(ngModel)]="parcel.length" required class="uk-input">
                    </div>

              <div class="uk-width-1-1 uk-margin-top uk-margin-bottom">
                  <p class="uk-heading-divider">
                      {{'recipient_data' | translate}}
                  </p>
              </div>


                          <div class="uk-width-1-1@m uk-margin-bottom uk-margin-top">
                              <label><input [disabled]="(userModel.role == 3)" class="uk-checkbox" type="checkbox" value="1" #recipient_private_person="ngModel" [(ngModel)]="parcel.recipient_private_person" autocomplete="none" name="recipient_private_person"> {{'recipient_private_person' | translate}}</label>
                          </div>


                            <div class="uk-width-1-2@m">
                                <label class="required">{{'recipient_and_company_name' | translate}}</label>
                                <input [disabled]="(userModel.role == 3)" type="text" autocomplete="none" #recipient_name="ngModel" name="recipient_name" [(ngModel)]="parcel.recipient_name" required class="uk-input">
                            </div>

                            <div class="uk-width-1-2@m">
                                <label>{{'contact_person' | translate}}</label>
                                <input [disabled]="(userModel.role == 3) || (recipient_private_person.value == 1)" type="text" autocomplete="none" #contact="ngModel" name="recipient_contact" [(ngModel)]="parcel.recipient_contact" required class="uk-input">
                            </div>

                            <!--
                           <div class="uk-width-1-2@m">
                               <label>{{'recipient_email' | translate}}</label>
                               <input [disabled]="(userModel.role == 3)" type="email" autocomplete="none" #recipient_email="ngModel" name="recipient_email" [(ngModel)]="parcel.recipient_email" class="uk-input" email>
                           </div>
                           -->

                          <div class="uk-width-1-2@m">
                              <label class="required">{{'phone_number' | translate}}</label>
                               <input id="recipient_phone_number" [disabled]="(userModel.role == 3)" type="text" pattern="07[0-9]{8}|00[0-9]{11,12}|\+[0-9]{11,12}" title="Wymagany format 07XXXXXXXX, 00XXXXXXXXXXX lub +XXXXXXXXXXX" #recipient_phone_number="ngModel" [class.uk-form-danger]="recipient_phone_number.invalid" autocomplete="none" name="recipient_phone_number"  [(ngModel)]="parcel.recipient_phone_number" class="uk-input" required>
                          </div>

                          <div class="uk-width-1-2@m">
                            <label class="required">{{'town' | translate}}</label>
                            <input [disabled]="(userModel.role == 3)" [class.uk-form-danger]="destination_city.touched && parcel.destination_city_code == ''" (keyup)="searchCity(destination_city)" type="text" #destination_city="ngModel" autocomplete="off" name="destination_city" [(ngModel)]="parcel.destination_city" class="uk-input" required #clientField>


                            <div style="position: absolute; max-height: 200px;" class="uk-overflow-auto">
                              <ul class="uk-list uk-list-divider uk-position-relative uk-margin-remove-top" style="background-color: white; width: 100%;" *ngIf="cities.length > 0"  [style.width]="clientField.clientWidth + 'px'">
                                <li *ngFor="let city of cities" (click)="setCity(city)">{{city.name}} ({{city.state}})</li>
                              </ul>
                            </div>

                            <input type="hidden" #destination_city_code="ngModel" autocomplete="none" name="destination_city_code" [(ngModel)]="parcel.destination_city_code" class="uk-input">
                          </div>
                          <div class="uk-width-1-4@m">
                            <label class="required">{{'destination_post_code' | translate}}</label>
                            <input [disabled]="(userModel.role == 3)" type="text" #destination_post_code="ngModel" autocomplete="none" name="destination_post_code"  [(ngModel)]="parcel.destination_post_code"  class="uk-input" disabled>
                          </div>
                          <!--
                          <div class="uk-width-1-1@m">
                              <div class="uk-grid">
                                  <div class="uk-width-1-3@m">
                                      <label>{{'phone_prefix_2' | translate}}</label>
                                      <input [disabled]="(userModel.role == 3)" type="tel" #phone_prefix_2="ngModel" pattern="(\+)[0-9]{2,3}" [class.uk-form-danger]="phone_prefix_2.invalid" autocomplete="off" name="phone_prefix_2"  [(ngModel)]="parcel.phone_prefix_2" class="uk-input">
                                  </div>
                                  <div class="uk-width-2-3@m">
                                      <label>{{'recipient_phone_number_2' | translate}}</label>
                                      <input [disabled]="(userModel.role == 3)" type="tel" #recipient_phone_number_2="ngModel" pattern="[0-9]{9,10}" [class.uk-form-danger]="recipient_phone_number_2.invalid" autocomplete="none" name="recipient_phone_number_2"  [(ngModel)]="parcel.recipient_phone_number_2" class="uk-input">
                                  </div>
                              </div>
                          </div>

                          <div class="uk-width-1-4@m">
                              <label>{{'destination_street_type' | translate}}</label>
                              <input [disabled]="(userModel.role == 3)" type="text" #destination_street_type="ngModel" autocomplete="none" name="destination_street_type"  [(ngModel)]="parcel.destination_street_type"  class="uk-input" [required]=" (destination_city.touched && destination_address_note.value == '') && (hasStreetTypes == true)"  [class.uk-form-danger]="destination_street_type.invalid" [readOnly]="hasStreetTypes">
                          </div>

                          <div class="uk-width-1-2@m">
                              <label>{{'destination_street' | translate}}</label>
                              <input [disabled]="(userModel.role == 3)" type="text" (keyup)="searchStreet(parcel.destination_city_code, parcel.destination_street)" #destination_street="ngModel" autocomplete="none" name="destination_street"  [(ngModel)]="parcel.destination_street"  class="uk-input" [required]=" destination_city.touched && destination_street.value == '' && destination_address_note.value == ''"  [class.uk-form-danger]="destination_street.invalid">
                              <div style="position: absolute; max-height: 200px;" class="uk-overflow-auto">
                                  <ul class="uk-list uk-list-divider uk-position-relative uk-margin-remove-top" style="background-color: white; width: 100%;"  *ngIf="streets.length > 0"  [style.width]="clientField.clientWidth + 'px'">
                                      <li *ngFor="let street of streets" (click)="setStreet(street)">{{street.typeEn}} {{street.nameEn}}</li>
                                  </ul>
                              </div>
                          </div>

                          <div class="uk-width-1-4@m">
                              <label>{{'destination_address' | translate}}</label>
                              <input [disabled]="(userModel.role == 3)" type="text" #destination_address="ngModel" autocomplete="none" name="destination_address"  [(ngModel)]="parcel.destination_address"  class="uk-input" [required]=" destination_city.touched && destination_address.value == '' && destination_address_note.value == ''"  [class.uk-form-danger]="destination_address.invalid">
                          </div>
                          -->

                          <div class="uk-width-3-4@m">
                              <label class="required">{{'address' | translate}}</label>
                              <input [disabled]="(userModel.role == 3)" type="text" #destination_address_note="ngModel" autocomplete="none" name="destination_address_note"  [(ngModel)]="parcel.destination_address_note"  class="uk-input" required>
                          </div>

                          <div class="uk-width-1-1@m" *ngIf="parcel.locker">
                            <label class="required">{{'locker' | translate}}</label>
                            <input disabled type="text" #destination_address_note="ngModel" autocomplete="none" name="locker_name"  [(ngModel)]="parcel.locker_name"  class="uk-input" required>
                          </div>

                          <div class="uk-width-1-1@m">
                              <label class="required">{{'declared_content' | translate}}</label>
                              <input [disabled]="(userModel.role == 3)" type="text" #declared_content="ngModel" autocomplete="none" name="declared_content"  [(ngModel)]="parcel.declared_content"  class="uk-input">
                          </div>

                          <div class="uk-width-2-5@m" title="{{'declared_value_description' | translate}}">
                              <label>{{'declared_value_short' | translate}}</label>
                              <input [disabled]="(userModel.role == 3)" type="number" step="0.01" min="0" #declared_value="ngModel" autocomplete="none" name="declared_value"  [(ngModel)]="parcel.declared_value"  class="uk-input">
                          </div>

                          <div class="uk-width-3-5@m" title="{{'declared_value_description' | translate}}">
                            <label>{{'recipient_email' | translate}}</label>
                            <input [disabled]="(userModel.role == 3)" type="email" #recipient_email="ngModel" autocomplete="none" name="recipient_email"  [(ngModel)]="parcel.recipient_email"  class="uk-input">
                          </div>

                          <!--
                          <div class="uk-width-1-2@m" *ngIf="userModel.role < 3">
                              <label>{{'insurance_value' | translate}}</label>
                              <input [disabled]="(userModel.role == 3)" type="number" step="0.01" #insurance_value="ngModel" autocomplete="none" name="insurance_value"  [(ngModel)]="parcel.insurance_value"  class="uk-input">
                          </div>
                          -->

                          <div class="uk-width-1-1 uk-margin-top uk-margin-bottom">
                              <p class="uk-heading-divider">
                                  {{'parcel_aggrements' | translate}}
                              </p>
                          </div>


                          <div class="uk-width-1-1@m">
                              <label><input [disabled]="(userModel.role == 3)" class="uk-checkbox" type="checkbox" value="1" #cash_on_delivery="ngModel" [(ngModel)]="parcel.cash_on_delivery" autocomplete="none" name="cash_on_delivery" #c> {{'cash_on_delivery' | translate}}</label>
                          </div>

                          <div class="uk-width-1-2@m uk-margin-top uk-margin-bottom" *ngIf="c.checked">
                              <label>{{'cash_on_delivery_value' | translate}}</label>
                              <input [disabled]="(userModel.role == 3)" type="number" step="0.01" min="0" #cash_on_delivery_value="ngModel" autocomplete="none" name="cash_on_delivery_value" [(ngModel)]="parcel.cash_on_delivery_value"  class="uk-input">
                          </div>
                          <div class="uk-width-1-1@m uk-margin-bottom">
                            <div class="uk-grid">
                              <div class="uk-width-1-3@m uk-margin-bottom uk-padding-small-right uk-margin-small-top" *ngIf="displayPrice">
                                <label>{{'price' | translate}}</label>
                                <input type="text" name="Price"class="uk-input" [(ngModel)]="parcel.price" disabled>
                              </div>
                              <div class="uk-width-1-3@m uk-margin-bottom uk-padding-small-left uk-margin-small-top" *ngIf="displayPrice">
                                <label>{{'netto' | translate}}</label>
                                <input type="text" name="NetPrice" class="uk-input" [(ngModel)]="parcel.net_price" disabled>
                              </div>
                              <div class="uk-width-1-3@m uk-margin-bottom uk-padding-small-left uk-margin-small-top" *ngIf="displayPrice">
                                <label>{{'ron_price' |translate}}</label>
                                <input type="text" name="subPriceRon" class="uk-input" [(ngModel)]="parcel.ron" disabled>
                              </div>

                              <div class="uk-width-1-3@m uk-margin-bottom uk-margin-small-top">
                                <button class="uk-button uk-button-primary uk-width-1-1 uk-margin-top" [disabled]="parcelForm.form.invalid" *ngIf="isCorrect && (userModel.role < 3) && !parcel.return_parcel">{{'save' | translate}}</button>
                                <button class="uk-button uk-button-primary uk-width-1-1 uk-margin-top" [disabled]="parcelForm.form.invalid" *ngIf="!isCorrect  && (userModel.role < 3) && !parcel.return_parcel">{{'check' | translate}}</button>
                              </div>
                            </div>
                          </div>

                     </div>

                 </form>
             </div>

             <div class="uk-width-1-2@m">
                 <p class="uk-heading-divider">{{'statuses' | translate}}</p>
                 <table class="uk-table uk-width-1-1 uk-table-hover uk-table-divider">
                     <thead>
                     <tr>
                         <th>{{'date' | translate}}</th>
                         <th>{{'status' | translate}}</th>
                     </tr>
                     </thead>
                     <tbody *ngIf="statuses?.length > 0">
                     <tr *ngFor="let status of statuses" (click)="goToProblem(status.id_problem)">
                         <td><kbd>{{ status.when }}</kbd></td>
                         <td>{{ ((status.displayed_name).replace('!!problem_name!!', status.rule)).replace('!!problem_code!!', status.code) }}

                           <span *ngIf="status.where != null">({{ (status.where).replace('or. ', '') }})
                               <button class="uk-button padding-horizontal-small" style="float: right; margin-top: 0" [attr.uk-tooltip]="'Usuń status'" (click)="onStatus(status.id)"  *ngIf="status.id_courier == 1 && 3 > userModel.role && status.technical == 0"><span uk-icon="trash"></span></button>
                           </span>
                           <small *ngIf="status.technical && 3 > userModel.role && status.description?.length > 0">
                             <br>{{ status.description }}
                           </small>
                           <small *ngIf="3 > userModel.role" title="Login użytkownika który nadał status"><br>{{status.email}}</small>
                         </td>
                     </tr>
                     </tbody>
                     <tbody *ngIf="statuses?.length == 0">
                     <tr>
                         <td colspan="2">{{'no_data' | translate}}</td>
                     </tr>
                     </tbody>
                 </table>
             </div>
              <div *ngIf="userModel.role == 0" class="uk-width-1-1@m">
                <ul class="mobile-overflow uk-accordion" uk-accordion>
                  <li class="uk-close">
                    <p class="uk-accordion-title uk-heading-divider" href="#" [title]="'Pozostałe informacje służące diagnostyce'">Więcej...</p>
                    <div class="uk-accordion-content">
                      <div class="uk-grid">
                        <div class="uk-width-1-3@m">
                          <label>Droga utworzenia</label>
                          <input class="uk-input" type="text" [(ngModel)]="parcel.creator" disabled>
                        </div>
                        <div class="uk-width-1-3@m">
                          <label>Poprawnie zarejestrowno przesyłkę o</label>
                          <input class="uk-input" type="text" [(ngModel)]="parcel.correctly_requested_at" disabled>
                        </div>
                        <div class="uk-width-1-3@m">
                          <label>Podwójnie sprawdzono doręczenie</label>
                          <select class="uk-select" [(ngModel)]="parcel.delivery_twice_checked" disabled>
                            <option value="0">Nie</option>
                            <option value="1">Tak</option>
                          </select>
                        </div>
                        <div class="uk-width-1-3@m">
                          <label>Usługa wyjściowa</label>
                          <input class="uk-input" type="text" [(ngModel)]="parcel.out_service">
                        </div>
                        <div class="uk-width-1-3@m">
                          <label>Domyślne dane API</label>
                          <select class="uk-select" [(ngModel)]="parcel.default_api" disabled>
                            <option value="0">Nie</option>
                            <option value="1">Tak</option>
                          </select>
                        </div>
                        <div class="uk-width-1-3@m">
                          <label>Notatka</label>
                          <input class="uk-input" type="text" [ngModel]="parcel.note" disabled>
                        </div>
                      </div>
                   </div>
                  </li>
                </ul>
              </div>
         </div>

     </div>
 </div>

</div>

<div id="label" class="label-modal" uk-modal>
 <div class="uk-modal-dialog">
     <button class="uk-modal-close-default" type="button" uk-close></button>

     <div class="uk-modal-header">
         <h2 class="uk-modal-title">{{'paper_size' | translate}}</h2>
     </div>

     <div class=" uk-modal-body">

         <div class="uk-alert" uk-alert *ngIf="generateProgressAlert">
             <p>{{'generate_progress_alert' | translate}}</p>
         </div>

         <a (click)="downloadFile( api + 'parcel/label/' + parcel.id + '/dl', parcel.internal_number + '.pdf', 'dl')"
            class="uk-button uk-button-primary uk-width-1-1">
           297 × 210
         </a>
         <a (click)="downloadFile( api + 'parcel/label/' + parcel.id + '/100150', parcel.internal_number + '.pdf', '100150')"
            class="uk-button uk-button-primary uk-width-1-1 uk-margin-top">
           100 x 150
         </a>

         <a (click)="addNextParcel()" class="uk-button uk-button-danger uk-width-1-1 uk-margin-top">{{"add_next_parcel" | translate}}</a>

     </div>
 </div>
</div>


<div id="cancel" class="cancel-modal" uk-modal>
 <div class="uk-modal-dialog">
     <button class="uk-modal-close-default" type="button" uk-close></button>

     <div class="uk-modal-header">
         <h2 class="uk-modal-title">{{'cancel_parcel' | translate}}</h2>
     </div>

     <div class=" uk-modal-body">
         <div class="uk-width-1-1@m">
             <label>{{'comment_cancel_parcel' | translate}}</label>
             <input type="text" autocomplete="none" name="comment"  [(ngModel)]="comment" required class="uk-input">
         </div>

         <div class="uk-margin">
             <button class="uk-button uk-button-danger uk-width-1-1 uk-margin-top" (click)="onCancelParcel()" [disabled]="comment.length < 4">{{'cancel_parcel' | translate}}</button>
         </div>
     </div>
 </div>
</div>
<div id="changeStatus" class="changeStatus-modal" uk-modal>
  <div class="uk-modal-dialog">
    <button class="uk-modal-close-default" type="button" uk-close></button>

    <div class="uk-modal-header">
      <h2 class="uk-modal-title">{{'change_status' | translate}}</h2>
    </div>

    <div class=" uk-modal-body">
      <input [(ngModel)]="statusModel.id" name="id" type="hidden">

      <div class="uk-width-1-1@m">
        <label>{{'choose_status' | translate}}</label>
        <select name="id_status_type" required class="uk-input" [(ngModel)]="statusModel.id_status_type">
          <option *ngFor="let statusType of statusTypes" value="{{statusType.id}}">{{statusType.displayed_name}}
         </option>
        </select>
      </div>

      <div class="uk-width-1-1@m">
        <label>{{'when' | translate}}</label>
        <input type="datetime-local" autocomplete="none" name="when"  [(ngModel)]="statusModel.when" required data-uk-timepicker class="uk-input">
      </div>

      <div class="uk-width-1-1@m">
        <label>{{'where' | translate}}</label>
        <input type="text" autocomplete="none" name="where" [(ngModel)]="statusModel.where" required class="uk-input">
      </div>

      <div class="uk-margin">
        <button class="uk-button uk-button-primary uk-width-1-1 uk-margin-top" (click)="onStatusChange()">{{'change_status' | translate}}</button>
      </div>
    </div>
  </div>
</div>

<div id="adoptBufferParcelToClientModal" class="adoptBufferParcelToClientModal" uk-modal>
  <div class="uk-modal-dialog">
    <button class="uk-modal-close-default" type="button" uk-close></button>

    <div class="uk-modal-header">
      <h2 class="uk-modal-title">{{'Wymuś przypisanie do klienta' | translate}}</h2>
    </div>

    <div class=" uk-modal-body">
      <div class="uk-grid">
        <div class="uk-width-2-3">
          <label>Klient</label>
          <input autocomplete="off" [class.uk-form-danger]="adoptBufferParcelToClientInput.touched && adoptBufferParcelToClientInput.value == ''" (keyup)="searchClientForAdoptBufferParcelToClientForm(adoptBufferParcelToClientInput)" type="text" #adoptBufferParcelToClientInput="ngModel" name="clientFCI" [(ngModel)]="adoptBufferParcelToClientClientName" class="uk-input" required #adoptBufferParcelToClientI>
          <div style="position: absolute; max-height: 200px;" class="uk-overflow-auto">
            <ul class="uk-list uk-list-divider uk-position-relative uk-margin-remove-top" style="background-color: white; width: 100%;" *ngIf="adoptBufferParcelToClientClientList.length > 0" [style.width]="adoptBufferParcelToClientI.clientWidth + 'px'">
              <li *ngFor="let client of adoptBufferParcelToClientClientList" (click)="setAdoptBufferParcelToClientClientData(client);" style="padding-left: 5px!important;">{{client.name}}</li>
            </ul>
          </div>
          <input type="hidden" autocomplete="none" [(ngModel)]="adoptBufferParcelToClientForm.id_client" class="uk-input">
        </div>
        <div class="uk-width-1-3">
          <label>Przesyłka emag</label>
          <select [(ngModel)]="adoptBufferParcelToClientForm.is_emag_parcel" class="uk-select">
            <option value="1">Tak</option>
            <option value="0">Nie</option>
          </select>
        </div>
      </div>

      <div class="uk-margin">
        <button class="uk-button uk-button-primary uk-width-1-1 uk-margin-top" (click)="submitAdoptBufferParcelToClientForm()">Zapisz</button>
      </div>
    </div>
  </div>
</div>

<div id="adoptUnprocessableParcelToClientModal" class="adoptUnprocessableParcelToClientModal" uk-modal>
  <div class="uk-modal-dialog">
    <button class="uk-modal-close-default" type="button" uk-close></button>

    <div class="uk-modal-header">
      <h2 class="uk-modal-title">{{'Wymuś przypisanie do klienta' | translate}}</h2>
    </div>

    <div class=" uk-modal-body">
      <div class="uk-grid">
        <div class="uk-width-1-2">
          <label>Klient</label>
          <input autocomplete="off" [class.uk-form-danger]="adoptUnprocessableParcelToClientInput.touched && adoptUnprocessableParcelToClientInput.value == ''" (keyup)="searchClientForAdoptUnprocessableParcelToClientForm(adoptUnprocessableParcelToClientInput)" type="text" #adoptUnprocessableParcelToClientInput="ngModel" name="adoptUnprocessableParcelToClientInput" [(ngModel)]="adoptUnprocessableParcelToClientClientName" class="uk-input" required #adoptUnprocessableParcelToClientI>
          <div style="position: absolute; max-height: 200px;" class="uk-overflow-auto">
            <ul class="uk-list uk-list-divider uk-position-relative uk-margin-remove-top" style="background-color: white; width: 100%;" *ngIf="adoptUnprocessableParcelToClientClientList.length > 0" [style.width]="adoptUnprocessableParcelToClientI.clientWidth + 'px'">
              <li *ngFor="let client of adoptUnprocessableParcelToClientClientList" (click)="setAdoptUnprocessableParcelToClientClientData(client);" style="padding-left: 5px!important;">{{client.name}}</li>
            </ul>
          </div>
          <input type="hidden" #forced_client_id="ngModel" autocomplete="none" [(ngModel)]="adoptUnprocessableParcelToClientForm.id_client" class="uk-input">
        </div>
        <div class="uk-width-1-4">
          <label title="Przesyłka emag">P.emag</label>
          <select [(ngModel)]="adoptUnprocessableParcelToClientForm.is_emag_parcel" class="uk-select">
            <option value="1">Tak</option>
            <option value="0">Nie</option>
          </select>
        </div>
        <div class="uk-width-1-4">
          <label title="Przesyłka zwrotna">P. zwrotna</label>
          <select [(ngModel)]="adoptUnprocessableParcelToClientForm.return" class="uk-select">
            <option value="1">Tak</option>
            <option value="0">Nie</option>
          </select>
        </div>
      </div>

      <div class="uk-margin">
        <button class="uk-button uk-button-primary uk-width-1-1 uk-margin-top" (click)="submitAdoptUnprocessableParcelToClientForm()">Zapisz</button>
      </div>
    </div>
  </div>
</div>

<div id="requestPickupModal" class="requestPickup-modal" uk-modal>
  <div class="uk-modal-dialog">
    <button class="uk-modal-close-default" type="button" uk-close></button>

    <div class="uk-modal-header">
      <h2 class="uk-modal-title">{{'Zamów kuriera po odbiór przesyłki' | translate}}</h2>
    </div>

    <div class=" uk-modal-body">
      <div class="uk-grid">
        <div class="uk-width-1-2">
          <label>Data odbioru {{parcel.in_pickup_date}}</label>
          <input class="uk-input"
                 type="date"
                 [(ngModel)]="requestPickupForm.pickupDate"
          >
        </div>
        <div class="uk-width-1-2">
          <label>Max. godzina odbioru przesyłki</label>
          <input class="uk-input"
                 type="time"
                 [(ngModel)]="requestPickupForm.maxPickupHour"
          >
        </div>
      </div>

      <div class="uk-margin">
        <button class="uk-button uk-button-primary uk-width-1-1 uk-margin-top" (click)="submitRequestPickup()">Zamów kuriera</button>
      </div>
    </div>
  </div>
</div>

