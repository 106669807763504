import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ParcelsService} from '../../../services/parcels/parcels.service';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-parcel-public',
  templateUrl: './parcel-public.component.html',
  styleUrls: ['./parcel-public.component.scss']
})
export class ParcelPublicComponent implements OnInit {
  id = null;
  statuses = [];
  error = false;
  activeLanguage = null;
  browserLanguage = null;
  availableLanguages = ['pl', 'ro'];

  constructor(private translate: TranslateService, private route: ActivatedRoute,
              private parcelsService: ParcelsService) {
  }

  ngOnInit() {
    this.browserLanguage = (navigator.language).split('-')[0];
    this.setDefaultLang();
    this.id = this.route.snapshot.paramMap.get('id');
    if (this.id != null) {
      this.searchParcel(this.id);
    }
  }

  searchParcel(id) {
    this.parcelsService.getPublicParcelStatusHistory(id).subscribe(
      (response: any) => {
        response = response.sort((a, b) => {
          return (a.when < b.when) ? 1 : ((a.when > b.when) ? -1 : 0);
        });
        this.statuses = response;
        this.error = false;
        window.history.pushState(null, null, `/tracking/${id}`);
        if (response.length === 0) {
          this.error = true;
        }
      }, e => {
        this.error = true;
      }
    );
  }


  setDefaultLang() {
    const lastSelectedLanguage = localStorage.getItem('language');
    let languageToSet = 'ro';
    if (lastSelectedLanguage == null) {
      if (this.availableLanguages.indexOf(this.browserLanguage) > -1) {
        languageToSet = this.browserLanguage;
      }
    } else {
      languageToSet = lastSelectedLanguage;
    }
    localStorage.setItem('language', languageToSet);
    this.translate.setDefaultLang(languageToSet);
    this.activeLanguage = languageToSet;
  }

  onChangeLanguage(selectedLanguage) {
    if (this.availableLanguages.indexOf(selectedLanguage) > -1) {
      localStorage.setItem('language', selectedLanguage);
      this.translate.setDefaultLang(selectedLanguage);
      this.activeLanguage = selectedLanguage;
    }
  }


}
