<div class="uk-grid">
  <div class="uk-width-1-1 navbar">

    <p class="nav-text">
      {{'tracking_re'|translate}}
      <button
        class="uk-button uk-button-primary padding-horizontal-small uk-float-right uk-margin-small-top uk-margin-small-right"
        (click)="onChangeLanguage('pl')"
        [attr.uk-tooltip]="'PL'"><img src="/assets/img/poland.png" alt="Poland" style="width: 20px;"></button>
      <button class="uk-button uk-button-primary padding-horizontal-small uk-float-right uk-margin-small-top "
              (click)="onChangeLanguage('ro')"
              [attr.uk-tooltip]="'RO'"><img src="/assets/img/romania.png" alt="Romania" style="width: 20px;"></button>
    </p>

    <!--
        <select class="uk-select" (change)="onChangeLanguage()">
          <option value="pl" [selected]="lang=='pl'">PL</option>
          <option value="ro" [selected]="lang=='ro'">RO</option>
        </select>
    -->

  </div>

  <div class="uk-width-1-1 uk-margin-medium-top">
    <div class="uk-container uk-margin-auto">
      <div class="uk-first-column uk-margin-top" style="margin: 0 auto; max-width: 800px">

        <div class="uk-card uk-card-default uk-card-body uk-margin-medium-right">


          <div class="uk-text-center uk-margin-bottom">
            <img src="/assets/img/re-logo.png" class="img-fluid" routerLink="/">
          </div>


          <form class="" #parcelForm="ngForm" (ngSubmit)="searchParcel(id)">

            <div class="uk-alert uk-alert-danger" *ngIf="error">
              {{"parcel_search_error" | translate}}
            </div>


            <div>
              <label>{{'parcel_number' | translate}}</label>
              <input name="number" class="uk-input" type="text" required [(ngModel)]="id">
            </div>

            <div class="uk-margin-top">
              <button [disabled]="!parcelForm.valid" type="submit" class="uk-button uk-button-primary uk-width-1-1">
                <span *ngIf="statuses.length == 0">{{'search' | translate}}</span>
                <span *ngIf="statuses.length != 0">{{'search_next' | translate}}</span>
              </button>
            </div>

          </form>

          <table class="uk-table uk-width-1-1 uk-table-hover uk-table-divider" *ngIf="statuses.length > 0">
            <thead>
            <tr>
              <th style="width: 35%">{{'date' | translate}}</th>
              <th style="width: 65%">{{'status' | translate}}</th>
            </tr>
            </thead>
            <tbody *ngIf="statuses?.length > 0">
            <tr *ngFor="let status of statuses">
              <td *ngIf="activeLanguage == 'pl' || (activeLanguage == 'ro' && status.displayed_name_ro != null)">
                <kbd>{{ status.when }}</kbd></td>
              <td *ngIf="activeLanguage == 'pl'">
                {{ status.displayed_name }}
                <span *ngIf="status.where != null">({{ (status.where).replace('or. ', '') }})</span>
              </td>
              <td *ngIf="activeLanguage=='ro' && status.displayed_name_ro!=null">
                {{ status.displayed_name_ro }}
                <span *ngIf="status.where != null">({{ (status.where).replace('or. ', '') }})</span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>

      </div>

    </div>


  </div>
</div>
