<div class="uk-grid">
    <div class="uk-width-1-1 navbar">
        <p class="nav-text">
          {{'add_parcel' | translate}}
          <button *ngIf="userModel.role==0" class="uk-button uk-button-primary padding-horizontal-small button-opt" (click)="fillRandomData()">
            <span uk-icon="paint-bucket"  [attr.uk-tooltip]="'Wypełnij losowymi danymi'"></span>
          </button>
        </p>
    </div>

  <div class="uk-width-1-1 inner-shadow">

    <div class="uk-card uk-card-default uk-card-body uk-margin-medium-right uk-margin-medium-top">
            <div class="mobile-overflow">
                <form #parcelForm="ngForm" (ngSubmit)="onCreateParcel(parcelForm)" autocomplete="off" >
                    <div class="uk-grid">


                        <div class="uk-width-1-1 uk-margin-bottom" *ngIf="userModel.role < 3">
                            <p class="uk-heading-divider">
                                {{'client_data' | translate}}
                            </p>
                        </div>

                        <div class="uk-width-1-2@m" *ngIf="userModel.role < 3" >
                            <label class="required">{{'name' | translate}}</label>
                            <input autocomplete="off" [class.uk-form-danger]="clientInput.touched && id_client.value == ''" (keyup)="searchClient(clientInput)" type="text" #clientInput="ngModel" name="client" [(ngModel)]="client" class="uk-input" required #clientField>

                          <div style="position: absolute; max-height: 200px;" class="uk-overflow-auto">
                            <ul class="uk-list uk-list-divider uk-position-relative uk-margin-remove-top" style="background-color: white; width: 100%;" *ngIf="clients.length > 0" [style.width]="clientField.clientWidth + 'px'">
                                <li *ngFor="let client of clients" (click)="setClient(client); checkParcelPrice();">{{client.name}}</li>
                              </ul>
                            </div>

                            <input type="hidden" #id_client="ngModel" autocomplete="none" name="id_client" [(ngModel)]="idClient"  class="uk-input">
                        </div>

                        <div class="uk-width-1-1 uk-margin-bottom uk-margin-top">
                            <p class="uk-heading-divider">
                                {{'parcel_size' | translate}}
                            </p>
                        </div>

                        <div class="uk-width-1-4@m">
                            <label class="required">{{'weight' | translate}}</label>
                            <input (change)="checkParcelPrice()" [(ngModel)]="Weight" type="number" placeholder="0" value=""step="0.01" min="0" #weight="ngModel" autocomplete="none" name="weight" ngModel required class="uk-input">
                        </div>
                        <div class="uk-width-1-4@m">
                            <label class="required">{{'height' | translate}}</label>
                            <input (change)="checkParcelPrice()" [(ngModel)]="Height" type="number" placeholder="0" value="" step="0.01" min="0" #height="ngModel" autocomplete="none" name="height" ngModel required class="uk-input">
                        </div>
                        <div class="uk-width-1-4@m">
                            <label class="required">{{'width' | translate}}</label>
                            <input (change)="checkParcelPrice()" [(ngModel)]="Width" type="number" placeholder="0" value="" step="0.01" min="0" #width="ngModel" autocomplete="none" name="width" ngModel required class="uk-input">
                        </div>
                        <div class="uk-width-1-4@m">
                            <label class="required">{{'length' | translate}}</label>
                            <input (change)="checkParcelPrice()" [(ngModel)]="Length" type="number" placeholder="0" value="" step="0.01" min="0" #length="ngModel" autocomplete="none" name="length" ngModel required class="uk-input">
                        </div>

                        <div class="uk-width-1-1 uk-margin-top uk-margin-bottom">
                            <p class="uk-heading-divider">
                                {{'recipient_data' | translate}}
                            </p>
                        </div>

                        <div class="uk-width-1-1@m uk-margin-bottom uk-margin-top">
                            <label><input class="uk-checkbox" type="checkbox" value="1" #recipient_private_person="ngModel" [ngModel]="isPrivatePerson" name="recipient_private_person"> {{'recipient_private_person' | translate}}</label>
                        </div>

                        <div class="uk-width-1-2@m">
                            <label class="required">{{'recipient_and_company_name' | translate}}</label>
                            <input type="text" autocomplete="none" #recipient_name="ngModel" name="recipient_name" [ngModel]="recipientName" ngModel required class="uk-input">
                        </div>

                        <div class="uk-width-1-2@m">
                            <label>{{'contact_person' | translate}}</label>
                            <input type="text" autocomplete="none" #contact="ngModel" name="recipient_contact" ngModel required class="uk-input" [disabled]="recipient_private_person.value == 1">
                        </div>

                       <div class="uk-width-1-2@m">
                           <label class="required" title="Wymagany format 07XXXXXXXX, 00XXXXXXXXXXX lub +XXXXXXXXXXX">{{'phone_number' | translate}}</label>
                           <input type="text" id="recipient_phone_number" #recipient_phone_number="ngModel" value="07" pattern="07[0-9]{8}|00[0-9]{11,12}|\+[0-9]{11,12}" title="Wymagany format 07XXXXXXXX, 00XXXXXXXXXXX lub +XXXXXXXXXXX" (change)="touchRecipientPhoneNumberField()" [ngModel]="recipientPhoneNumber" [class.uk-form-danger]="recipient_phone_number.invalid && recipientPhoneNumberTouched" autocomplete="none" name="recipient_phone_number" ngModel class="uk-input">
                       </div>

                       <div class="uk-width-1-2@m">
                           <label class="required">{{'town' | translate}}</label>
                           <input [class.uk-form-danger]="destination_city.touched && destinationCityCode == ''" (keyup)="searchCity(destination_city)" type="text" #destination_city="ngModel" name="destination_city" [(ngModel)]="destinationCity" class="uk-input" required #cityField>

                         <div style="position: absolute; max-height: 200px;" class="uk-overflow-auto">
                           <ul class="uk-list uk-list-divider uk-position-relative uk-margin-remove-top" style="background-color: white; width: 100%;" [style.width]="cityField.clientWidth + 'px'" *ngIf="cities.length > 0">
                               <li *ngFor="let city of cities" (click)="setCity(city)">{{city.name}} ({{city.state}})</li>
                           </ul>
                         </div>

                           <input type="hidden" #destination_city_code="ngModel" autocomplete="none" name="destination_city_code" [(ngModel)]="destinationCityCode"  class="uk-input">
                       </div>

                       <div class="uk-width-1-4@m">
                           <label>{{'destination_post_code' | translate}}</label>
                           <input type="text" #destination_post_code="ngModel" autocomplete="none" name="destination_post_code" [(ngModel)]="destinationPostCode" class="uk-input" disabled>
                       </div>


                       <div class="uk-width-3-4@m">
                           <label class="required">{{'address' | translate}}</label>
                           <input type="text" #destination_address_note="ngModel" autocomplete="none" name="destination_address_note"  (keyup)="searchStreet(destinationCityCode, destination_address_note)" [(ngModel)]="destinationAddressNote" ngModel  class="uk-input" required #address>
                           <div style="position: absolute; max-height: 200px;" class="uk-overflow-auto">
                             <ul class="uk-list uk-list-divider uk-position-relative uk-margin-remove-top" style="background-color: white; width: 100%;" [style.width]="cityField.clientWidth + 'px'" *ngIf="streets.length > 0">
                               <li *ngFor="let street of streets" (click)="setStreet(street)">{{street.typeEn}} {{street.nameEn}}</li>
                             </ul>
                           </div>
                       </div>

                      <div class="uk-width-1-2@m">
                        <label>{{'locker' | translate}}</label>
                        <input type="text" autocomplete="none" #locker="ngModel" name="recipient_locker_identifier" ngModel class="uk-input" [ngModel]="lockerCode">
                      </div>

                      <div class="uk-width-1-2@m"></div>

                       <div class="uk-width-1-1@m">
                           <label class="required">{{'declared_content' | translate}}</label>
                           <input type="text" #declared_content="ngModel" autocomplete="none" [ngModel]="declaredContent" name="declared_content" ngModel class="uk-input" required>
                       </div>

                       <div class="uk-width-1-2@m">
                           <label>{{'declared_value' | translate}}</label>
                           <input type="number" step="0.01" #declared_value="ngModel" (click)="touchDeclaredValueField()" (focusout)="checkParcelPrice()" (change)="checkParcelPrice()" min="0" autocomplete="none" name="declared_value" [(ngModel)]="declaredValue" class="uk-input" >
                       </div>
                      <div class="uk-width-1-2@m">
                        <label>{{'recipient_email' | translate}}</label>
                        <input type="email" #recipient_email="ngModel"  autocomplete="none" name="recipient_email" [(ngModel)]="recipientEmail" class="uk-input">
                      </div>


                      <!--
                       <div class="uk-width-1-2@m" *ngIf="userModel.role < 3">
                           <label>{{'insurance_value' | translate}}</label>
                           <input type="number" step="0.01" #insurance_value="ngModel" autocomplete="none" name="insurance_value" ngModel class="uk-input">
                       </div>
                       -->

                       <div class="uk-width-1-1 uk-margin-top uk-margin-bottom" *ngIf="CODEnabled && !isReturn">
                           <p class="uk-heading-divider">
                               {{'parcel_aggrements' | translate}}
                           </p>
                       </div>


                       <div class="uk-width-1-1@m" *ngIf="CODEnabled && !isReturn">
                           <label><input (change)="checkParcelPrice()" class="uk-checkbox" type="checkbox" value="1" #cash_on_delivery="ngModel" ngModel autocomplete="none" [(ngModel)]="Cash_on_delivery" name="cash_on_delivery" #c> {{'cash_on_delivery' | translate}}</label>
                       </div>

                       <div class="uk-width-1-2@m uk-margin-top uk-margin-bottom" *ngIf="Cash_on_delivery">
                           <label>{{'cash_on_delivery_value' | translate}}</label>
                           <input type="number" step="0.01" #cash_on_delivery_value="ngModel"  min="0" autocomplete="none" name="cash_on_delivery_value" ngModel class="uk-input">
                       </div>

                       <!--
                       <div class="uk-width-1-1@m">
                           <label><input class="uk-checkbox" type="checkbox" value="1" #this_side_up="ngModel" ngModel autocomplete="none" name="this_side_up"> {{'this_side_up' | translate}}</label>
                       </div>

                       <div class="uk-width-1-1@m">
                           <label><input class="uk-checkbox" type="checkbox" value="1" #not_expose_to_frost="ngModel" ngModel autocomplete="none" name="not_expose_to_frost"> {{'not_expose_to_frost' | translate}}</label>
                       </div>

                       <div class="uk-width-1-1@m">
                           <label><input class="uk-checkbox" type="checkbox" value="1" #liquid="ngModel" ngModel autocomplete="none" name="liquid"> {{'liquid' | translate}}</label>
                       </div>

                       <div class="uk-width-1-1@m">
                           <label><input class="uk-checkbox" type="checkbox" value="1" #caution="ngModel" ngModel autocomplete="none" name="caution"> {{'caution' | translate}}</label>
                       </div>
                       -->
                      <div class="uk-width-1-1@m uk-margin-small-top" *ngIf="displayPrice">
                        <p class="uk-heading-divider">
                          {{'parcel_price' | translate}}
                        </p>
                        <div class="uk-grid uk-padding-remove-top">
                          <div class="uk-width-3-4@m uk-padding-remove-top">
                            <div class="uk-grid uk-padding-remove-top">
                              <div class="uk-width-1-4@m uk-margin-bottom uk-padding-small-right uk-margin-small-top">
                                <label><b>{{'full_price' | translate}}</b></label>
                                <input type="text" name="Price" class="uk-input" [(ngModel)]="Price" disabled>
                              </div>
                              <div class="uk-width-1-4@m uk-margin-bottom uk-padding-small-left uk-margin-small-top">
                                <label>{{'net_full_price'| translate}}</label>
                                <input type="text" name="NetPrice" class="uk-input" [(ngModel)]="NetPrice" disabled>
                              </div>
                              <div class="uk-width-1-4@m uk-margin-bottom uk-padding-small-right uk-margin-small-top">
                                <label>{{'pln_price' | translate}}</label>
                                <input type="text" name="SubPricePLN" class="uk-input" [(ngModel)]="SubPricePLN" disabled>
                              </div>
                              <div class="uk-width-1-4@m uk-margin-bottom uk-padding-small-right uk-margin-small-top" >
                                <label>{{'ron_price' | translate}}</label>
                                <input type="text" name="SubPriceRON" class="uk-input" [(ngModel)]="SubPriceRON" disabled>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="uk-width-3-4@m uk-margin-bottom">
                        <button class="uk-button uk-button-primary" [disabled]="parcelForm.form.invalid || ajaxActive == true" *ngIf="isCorrect">{{'add' | translate}}</button>
                        <button class="uk-button uk-button-primary" [disabled]="parcelForm.form.invalid" *ngIf="!isCorrect">{{'check' | translate}}</button>
                      </div>
                    </div>
                </form>
            </div>

        </div>

    </div>

</div>

