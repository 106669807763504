import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Router} from "@angular/router";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class CalendarDaysService {

  private apiurl = '';

  constructor(private http: HttpClient, private router: Router) {
    this.apiurl = environment.api;
  }

  getYears(){
    const url = this.apiurl + 'calendar/years';
    return this.http.get(url);
  }

  getYearDays(year){
    const url = this.apiurl + `calendar/year/${year}/days`;
    return this.http.get(url);
  }

  addDay(form) {
    const url = this.apiurl + 'calendar/day';
    return this.http.post(url, form);
  }

  removeClient(id) {
    const url = this.apiurl + 'calendar/day';
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        id
      },
    };
    return this.http.delete(url, options);
  }
}
